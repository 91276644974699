import { useState } from 'react';
import filterIcon from '../../assets/images/filterIcon.svg';
import CustomDropdown from '../../components/common/CustomDropdown';
import { ICON_ENUM } from '../../constants/icons.constant';

const FilterBar = ({ sortBy, setSortBy }: any) => {
    const [category, setCategory] = useState('Courses');
    const [contentType, setContentType] = useState('Free');
    const [isOpen, setIsOpen] = useState(false);

    const handleCategoryChange = (e: any) => {
        setCategory(e.target.value);
    };

    const handleContentTypeChange = (e: any) => {
        setContentType(e.target.value);
    };

    const CustomRadio = ({ checked, onChange, label, value }: any) => {
        return (
            <div className="flex items-center mr-3">
                <div className={`w-4 h-4 border-2 rounded-full mr-2 ${checked ? 'bg-indigo-500 border-indigo-500' : 'border-gray-500'}`}>
                    {checked && <div className="w-3 h-3 bg-[#18142E] rounded-full mx-auto"></div>}
                </div>
                <label htmlFor={value} className="text-white">{label}</label>
                <input
                    type="radio"
                    id={value}
                    name="contentType"
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    className="opacity-0 absolute"
                />
            </div>
        );
    };

    const resetFilter = () => {
        setCategory('Courses')
        setSortBy('rating')
        setContentType('Free')
    }
    const options = [
        { value: 'rating', label: 'Rating' },
        { value: 'latest', label: 'Latest' },
        { value: 'topVisited', label: 'Top Visited' },
    ]

    const handleSortByChange = (selectedOption: string | number | any) => {
        setSortBy(selectedOption);
    };

    return (
        <>
            <div className='flex justify-between items-center my-2'>
                <div className='flex gap-2 items-center justify-start w-1/2'>
                    <div className={`${isOpen ? 'block' : 'hidden'} w-6 h-6 md:block`}>
                        <img src={filterIcon} alt='filterIcon' />
                    </div>
                    <span className='w-fit text-lg'>FILTER BY</span>
                </div>
                <button type='button' onClick={resetFilter} className='underline text-white hidden md:block'>Clear All</button>
                {!isOpen ? <div onClick={() => setIsOpen(prev => !prev)} className='md:hidden w-6 h-6'>
                    <img src={filterIcon} alt='filterIcon' />
                </div> :
                    <button
                        onClick={() => setIsOpen(prev => !prev)}
                        className="md:hidden btn btn-sm btn-circle btn-ghost"
                    // onClick={onClose}
                    >
                        <img src={ICON_ENUM.CROSS.icon} className="h-4" alt="filters" />
                    </button>}
            </div>
            <div className={`text-white flex md:flex-row flex-col items-center justify-between md:gap-0 gap-6 ${isOpen ? 'h-full' : 'h-0'} overflow-hidden transition-all md:h-full ease-in-out`}>
                <div className="flex md:flex-row flex-col md:space-x-4 md:gap-0 gap-4 w-full">
                    {/* <div className='flex flex-col gap-2 md:min-w-[200px]'>
                        <label htmlFor="category" className="mr-2">Category:</label>
                        <select
                            id="category"
                            value={category}
                            onChange={handleCategoryChange}
                            className="bg-transparent border border-[#DADADA] rounded-lg p-2 text-white px-2 py-1 placeholder:text-[#F2F2F23B]"
                        >
                            <option value="Courses">Courses</option>
                            <option value="Books">Books</option>
                            <option value="Videos">Videos</option>
                        </select>
                    </div> */}
                    <div className='flex items-start flex-col gap-2 md:min-w-[200px]'>
                        <label htmlFor="sortBy" className="mr-2 whitespace-nowrap">Sort By:</label>
                        {/* <select
                            id="sortBy"
                            value={sortBy}
                            onChange={handleSortByChange}
                            className="bg-transparent border border-[#DADADA] text-white px-2 py-1 rounded-lg p-2 placeholder:text-[#F2F2F23B]"
                        >
                            <option value="Top Rated">Top Rated</option>
                            <option value="Newest">Newest</option>
                            <option value="Popularity">Popularity</option>
                        </select> */}
                        <CustomDropdown
                            options={options}
                            value={sortBy}
                            onChange={handleSortByChange}
                        />
                    </div>
                    <div className='flex gap-2 flex-col'>
                        <label htmlFor="sortBy" className="mr-2">Content type:</label>
                        <div className="flex items-center">
                            <CustomRadio
                                checked={contentType === 'Free'}
                                onChange={handleContentTypeChange}
                                label="Free"
                                value="Free"
                            />
                            <CustomRadio
                                checked={contentType === 'Paid'}
                                onChange={handleContentTypeChange}
                                label="Paid"
                                value="Paid"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex gap-2' >
                    <button className="text-white px-4 py-1.5 rounded-full min-w-[150px] font-medium" style={{ background: 'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)' }}>
                        APPLY
                    </button>
                    <button onClick={resetFilter} type='button' className="md:hidden text-white px-4 py-1.5 rounded-full min-w-[150px] font-medium border-2 bg-transparent gradient-border border-t-[#4B03CE] border-b-[#F572B6] border-l-[#4B03CE] border-r-[#F572B6]">
                        Clear All
                    </button>
                </div>
            </div>
        </>
    );
};

export default FilterBar;
import bannerImage from '../../assets/images/features/banner-ai.png';
import secondaryBannerImg from '../../assets/images/features/bg-sec-1.jpeg';
import FeatureCommon from "../../components/featureCommon/FeatureCommon";
import { cardDataAi, cardDataAi2, cardDataAi3 } from '../../data/featureData';
const textData = {
    title: "AI-Powered Tools",
    subTitle: "Tailored Solutions for Users and Developers",
    heading1: "Smart Solutions for Everyday Needs",
    heading2: "Discover AI tools and products that simplify tasks, enhance decision-making, and offer personalized experiences. From AI-powered assistants to machine learning tools, explore products designed to make your life easier.",
    heading3: "Amplify Your AI Innovations",
    heading4: "Bring your AI product to a global audience. Leverage our platform’s reach to get in front of developers, businesses, and end-users alike. Our advanced analytics help you understand user engagement and optimize your product’s performance.",
    secondaryBannerDesc: "AI system used in healthcare to assist doctors by analyzing medical data, recommending treatments, and helping with diagnostics based on medical records."
}
const otherCardData = {
    card2: cardDataAi2,
    card3: cardDataAi3
}
const FeatureAi = () => {
    return (
        <div className="">
            <FeatureCommon topBgImage={bannerImage} cardData={cardDataAi} secondaryBannerImg={secondaryBannerImg} textData={textData} cardDataSecondary={otherCardData} />
        </div>
    )
}

export default FeatureAi
export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const apiEndpoints: { [key: string]: any } = {
  chatBot: '/chat-bot',
  socialSignin: {
    endpoint: '/v1/analytics/brand/getSocialSigninPage',
    method: API_METHOD.POST,
    params: {
      query: { platformName: '' },
    },
    payload: '',
  },
  linkedin: {
    addPage: true,
    getPages: {
      endpoint: '/v1/analytics/brand/getLinkedInCompanies',
      method: API_METHOD.POST,
      payload: '',
    },
    addPages: {
      endpoint: '/v1/analytics/brand/addLinkedinPage/',
      method: API_METHOD.POST,
      payload: '',
    },
  },
  facebook: {
    addPage: true,
    getPages: {
      endpoint: '/v1/analytics/brand/getFacebookPages',
      method: API_METHOD.POST,
      payload: '',
    },
    addPages: {
      endpoint: '/v1/analytics/brand/addLinkedinPage/',
      method: API_METHOD.POST,
      payload: '',
    },
    getFacebookStatistics: {
      endpoint: '/v1/analytics/brand/getFacebookStatistics',
      method: API_METHOD.POST,
      params: {
        query: { fromDate: '', toDate: '', timezone: '' },
      },
      payload: '',
    },
  },
  instagram: {
    addPage: false,
    // getPages: {
    //   endpoint: '/v1/analytics/brand/getFacebookPages',
    //   method: API_METHOD.POST,
    //   payload: '',
    // },
    // addPages: {
    //   endpoint: '/v1/analytics/brand/addLinkedinPage/',
    //   method: API_METHOD.POST,
    //   payload: '',
    // },
  },
  twitter: {
    addPage: false,
    getTwitterStatistics: {
      endpoint: '/v1/analytics/brand/getTwitterStatistics',
      method: API_METHOD.POST,
      params: {
        query: { fromDate: '', toDate: '', timezone: '' },
      },
      payload: '',
    },
    // getPages: {
    //   endpoint: '/v1/analytics/brand/getFacebookPages',
    //   method: API_METHOD.POST,
    //   payload: '',
    // },
    // addPages: {
    //   endpoint: '/v1/analytics/brand/addLinkedinPage/',
    //   method: API_METHOD.POST,
    //   payload: '',
    // },
  },
  createBrand: {
    endpoint: '/v1/analytics/brand/create-brand',
    method: API_METHOD.GET,
  },
  userProfile: {
    endpoint: '/v1/userProfile',
    method: API_METHOD.GET,
  },
  updateBrandName: {
    endpoint: '/v1/analytics/brand/update-brand-name-image',
    method: API_METHOD.POST,
    params: {
      path: { brandId: '' },
      query: { name: '', image: '' },
    },
    payload: '',
  },
  getSummaryStatistics: {
    endpoint: '/v1/analytics/brand/getSummaryStatistics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '', timezone: '' },
    },
    payload: '',
  },
  getLinkedinStatistics: {
    endpoint: '/v1/analytics/brand/getLinkedinStatistics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '', timezone: '' },
    },
    payload: '',
  },
  getInstagramStatistics: {
    endpoint: '/v1/analytics/brand/getInstagramStatistics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '', timezone: '' },
    },
    payload: '',
  },
  updatePassword: {
    endpoint: '/v1/updatePassword',
    method: API_METHOD.PUT,
    payload: { isNewUser: 'false', oldPassword: '', newPassword: '' },
  },
  deleteUser: {
    endpoint: '/v1/userProfile',
    method: API_METHOD.DELETE,
    payload: { email: '' },
  },
  paymentIntent: {
    endpoint: '/v1/payment/payment-intent',
    method: API_METHOD.POST,
    payload: {
      amount: 0.00,
      currency: '',
      paymentMethod: '',
    },
  },
  updateProfile: {
    endpoint: '/v1/updateProfile',
    method: API_METHOD.PUT,
    payload: {
      fullName: "",
      userName: "",
      phoneNumber: "",
      country: "",
      zipcode: "",
      state: "",
      ageGroup: "",
      address: "",
      city: "",
      aptSuite: "",
      businessWebsiteUrl: "",
      businessCategory: "",
      businessType: "",
      businessDescription: "",
      channelName: "",
      businessName: "",
      operationalLocation: ""
    },
  },
  // products dashboard apis
  getProductAnalyticsList: {
    endpoint: '/v1/analytics/brand/getProductAnalytics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '' },
    },
  },

  getDashboardSocialStatistics: {
    endpoint: '/v1/analytics/brand/getDashboardSummaryStatistics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '', timezone: '' },
    },
  },

  geFollowersStatistics: {
    endpoint: '/v1/analytics/brand/getFollowersStatistics',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '' },
    },
  },

  getAllPosts: {
    endpoint: '/v1/analytics/brand/getScheduledPosts',
    method: API_METHOD.POST,
    params: {
      query: { fromDate: '', toDate: '', timezone: "" },
    },
  }
};

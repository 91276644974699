import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { apiEndpoints } from '../constants/api-endpoints';
import makeApiCall from '../lib/apiCall';

const useStripeHook = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const paymentIntentCall = async ({currency, price, type}) => {
    let intentApi = {...apiEndpoints.paymentIntent};
    intentApi.payload.amount = parseFloat(price) ?? 0.0;
    intentApi.payload.currency = currency;
    intentApi.payload.paymentMethod = type;
    const intentResp = await makeApiCall(intentApi);
    return intentResp;
  };

  const cardPayment = async ({
    currency,
    price,
    type,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
  }) => {
    if (!elements) return {message: 'Elements not loaded', type: 'error'};

    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cardCvcElement = elements?.getElement(CardCvcElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);

    if (!cardNumberElement || !cardCvcElement || !cardExpiryElement) {
      return {message: 'Card elements not found', type: 'error'};
    }
    setIsLoading(true);
    const intentResp = await paymentIntentCall({currency, price, type});

    if (!intentResp || !intentResp.client_secret) {
      setIsLoading(false);
      return {message: 'Failed to create payment intent', type: 'error'};
    }

    const {error: paymentMethodError, paymentMethod} =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement
        // card: {
        //   number: cardNumberElement.value,
        //   cvc: cardCvcElement.value,
        //   exp_month: parseInt(exp_month, 10),
        //   exp_year: parseInt(exp_year, 10),
        // },
      });

    if (paymentMethodError) {
      setIsLoading(false);
      return {
        message: paymentMethodError.message ?? 'Something went wrong',
        type: 'error',
      };
    }

    const {error: confirmError, paymentIntent} =
      await stripe.confirmCardPayment(intentResp.client_secret, {
        payment_method: paymentMethod.id,
      });
      
      setIsLoading(false);

    if (confirmError) {
      return {
        message: confirmError.message ?? 'Something went wrong',
        type: 'error',
      };
    } else if (paymentIntent.status === 'succeeded') {
      return {
        message: 'Payment completed successfully',
        type: 'success',
      };
    }

    return { message: 'Unknown error occurred', type: 'error' };
  };

  return {isLoading, cardPayment};
};

export default useStripeHook;

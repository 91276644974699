export const generalItems = [
    {
        text: "Important updates and announcements.",
    },
    {
        text: "Changes in terms of service or privacy policy.",
    },
];

export const ContentItems = [
    {
        text: "Recommendations based on your viewing or browsing history.",
    },
    {
        text: "Allow new content to your favorite categories or genres.",
    },
    {
        text: "Mentions of your username or handle in public posts.",
    },
];
export const AccountItems = [
    {
        text: "Account activity alerts (e.g., suspicious login attempts).",
    },
    {
        text: "Successful login attempts from new devices or locations.",
    },
];
export const billItems = [
    {
        text: "Upcoming subscription renewals and payment reminders.",
    },
    {
        text: "Changes in subscription pricing or plan upgrades.",
    },
];
export const feedbackItems = [
    {
        text: "Participate in user research studies or usability testing.",
    },
    {
        text: "Your feedback leads to improvements on the platform.",
    },
];

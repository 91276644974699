export const CommunitySVG = (width = '40', height = '40') => {
  return (
    <svg
      className="border-2 border-[#4800CB] rounded-full"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
    >
      <circle
        cx="16.8326"
        cy="16.8326"
        r="16.817"
        fill="url(#paint0_linear_2684_13024)"
      />
      <path
        d="M12.3556 7.82863C12.449 7.57619 12.806 7.57619 12.8994 7.82863L14.673 12.6216C14.7024 12.701 14.7649 12.7636 14.8443 12.7929L19.6373 14.5665C19.8897 14.6599 19.8897 15.0169 19.6373 15.1104L14.8443 16.8839C14.7649 16.9133 14.7024 16.9759 14.673 17.0552L12.8994 21.8482C12.806 22.1007 12.449 22.1007 12.3556 21.8482L10.582 17.0552C10.5526 16.9759 10.49 16.9133 10.4107 16.8839L5.61769 15.1104C5.36525 15.0169 5.36525 14.6599 5.61769 14.5665L10.4107 12.7929C10.49 12.7636 10.5526 12.701 10.582 12.6216L12.3556 7.82863Z"
        fill="white"
      />
      <path
        d="M22.7552 16.6802C22.8486 16.4278 23.2056 16.4278 23.299 16.6802L24.1761 19.0505C24.2055 19.1299 24.2681 19.1924 24.3474 19.2218L26.7177 20.0989C26.9702 20.1923 26.9702 20.5494 26.7177 20.6428L24.3474 21.5199C24.2681 21.5492 24.2055 21.6118 24.1761 21.6912L23.299 24.0615C23.2056 24.3139 22.8486 24.3139 22.7552 24.0615L21.8781 21.6912C21.8487 21.6118 21.7861 21.5492 21.7068 21.5199L19.3364 20.6428C19.084 20.5494 19.084 20.1923 19.3364 20.0989L21.7068 19.2218C21.7861 19.1924 21.8487 19.1299 21.8781 19.0505L22.7552 16.6802Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2684_13024"
          x1="16.8326"
          y1="0.015625"
          x2="16.8326"
          y2="33.6496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF77B0" />
          <stop offset="1" stopColor="#7D3CF3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import {
  appSVG,
  courseSVG,
  exploreSVG,
  gameSVG,
  logoutSVG,
  manageProfileSVG,
  movieSVG,
  servicesSVG,
  settingSVG,
  subscriptionSVG,
  loginSVG,
} from '../assets/icons/menu';

export const ENUM_EXPLORE = [
  {
    id: 1,
    title: 'Home',
    path: '/explore',
    icon: (width?: string, height?: string) => exploreSVG(width, height),
    // activeIcon: DashBoardActiveIcon,
    subCategories: [
      {
        id: 102,
        title: 'Apps',
        path: '/explore/apps',
        icon: (width?: string, height?: string) => appSVG(width, height),
      },
      {
        id: 101,
        title: 'Games',
        path: '/explore/games',
        icon: (width?: string, height?: string) => gameSVG(width, height),
      },
      {
        id: 103,
        title: 'Movies',
        path: '/explore/movies-tvs',
        icon: (width?: string, height?: string) => movieSVG(width, height),
      },
      {
        id: 104,
        title: 'Courses',
        path: '/explore/courses',
        icon: (width?: string, height?: string) => courseSVG(width, height),
      },
      {
        id: 105,
        title: 'Services',
        path: '/explore/services',
        icon: (width?: string, height?: string) => servicesSVG(width, height),
      },
    ],
  },
  {
    id: 3,
    title: 'Manage Profile',
    path: '/explore/manageprofile',
    icon: (width?: string, height?: string) => manageProfileSVG(width, height),
    // activeIcon: ManageProfileActiveIcon,
  },
  {
    id: 5,
    title: 'Subscription',
    path: '/explore/subscriptions',
    icon: (width?: string, height?: string) => subscriptionSVG(width, height),
  },
  {
    id: 4,
    title: 'Settings',
    path: '/explore/settings',
    icon: (width?: string, height?: string) => settingSVG(width, height),
  },
  {
    id: 6,
    title: 'Logout',
    isExit: true,
    icon: (width?: string, height?: string) => logoutSVG(width, height),
  },
];

export const ENUM_GUEST_MOBILE_MENU = [
  {
    id: 102,
    title: 'Apps',
    path: '/explore/apps',
    icon: (width?: string, height?: string) => appSVG(width, height),
  },
  {
    id: 101,
    title: 'Games',
    path: '/explore/games',
    icon: (width?: string, height?: string) => gameSVG(width, height),
  },
  {
    id: 103,
    title: 'Movies & TV',
    path: '/explore/movies-tvs',
    icon: (width?: string, height?: string) => movieSVG(width, height),
  },
  {
    id: 104,
    title: 'Content Creator',
    path: '/explore/content-creator',
    icon: (width?: string, height?: string) => courseSVG(width, height),
  },
  {
    id: 105,
    title: 'Services',
    path: '/explore/services',
    icon: (width?: string, height?: string) => servicesSVG(width, height),
  },
  {
    id: 106,
    title: 'Login',
    path: '/login',
    icon: (width?: string, height?: string) => loginSVG(width, height),
  },
];

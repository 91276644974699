export const ROUTES_ENUM = {
    LOGIN: '/login',
    CREATOR_ROOT: '/dev/dashboard',
    CREATOR_SOCIAL_CALLBACK: '/callbackEndpoint',
    CREATOR_STRIP_CALLBACK: '/callbackEndpointStripCreator',
    CREATOR_SUBSCRIBE: '/subscribe',
    LINKEDIN: '/dev/analytics/linkedin',
    INSTAGRAM: '/dev/analytics/instagram',
    FACEBOOK: '/dev/analytics/facebook',
    TWITTER: '/dev/analytics/twitter',
}
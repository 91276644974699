export const COLOR_ENUM = {
  ACTIVE: '#219653',
  INACTIVE: '#EB5757',
  PENDING: '#2D9CDB',
  FILTER_BADGE: '#322781',
  FACEBOOK: 'linear-gradient(45deg, #1C36B7 8.66%, #1C98D3 90.78%)',
  YOUTUBE: 'linear-gradient(135deg, #FF0000 14.6%, #9B0000 85.41%)',
  INSTAGRAM: 'linear-gradient(135.29deg, #5342D6 13.8%, #7739C6 18.4%, #A52DB0 25.1%, #B729A8 28.46%, #CE257E 37.01%, #E82250 47.52%, #F2203E 52.8%, #F2203E 65.79%, #F32D40 67.35%, #F86C48 75.25%, #FB994E 81.93%, #FDB652 87.06%, #FEC053 90.03%)',
  X: '#FFFFFF',
  LINKEDIN: 'linear-gradient(90deg, #007BB8 0.12%, #0044E9 99.98%)',
  TIKTOK: 'linear-gradient(135deg, #5B5760 14.55%, #020003 60.22%, #000000 85.35%)',
  TWITCH: 'linear-gradient(161.27deg, #A436D2 -182.61%, #9146FF 280.18%)',
  BEHANCE: 'linear-gradient(135deg, #006EF8 14.6%, #212CB1 69.49%, #2B189C 85.41%)',
};

import DatePicker from '../../../../components/common/DatePicker';

interface AnalyticCompTopProps {
    title: string;
    calenderValue: { [key: string]: null | Date };
    onChangeCalender: (value: { [key: string]: null | Date }) => void;
}

const AnalyticCompTop = ({
    title = '',
    calenderValue,
    onChangeCalender,
}: AnalyticCompTopProps) => {
    return (
        <div className="header flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">{title}</h2>
            <DatePicker
                onChange={onChangeCalender}
                value={calenderValue}
                maxDate={new Date()}
            />
        </div>
    );
};

export default AnalyticCompTop;

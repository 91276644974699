export const getTokenFromCookies = () => {
    const name = 'authToken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null; // Return null if token is not found
};

export const decodeToken = (token: string) => {
    if (!token) return null;

    // JWT consists of three parts: header, payload, signature
    const payload = token.split('.')[1];

    if (!payload) return null;

    // Decode payload from base64 to a JSON string
    try {
        const decodedPayload = atob(payload);
        return JSON.parse(decodedPayload);
    } catch (e) {
        console.error('Invalid token');
        return null;
    }
};

// Scroll left function
export const scrollLeft = (scrollRef: any) => {
    scrollRef.current.scrollBy({
        left: -300, // Adjust scroll amount as needed
        behavior: 'smooth'
    });
};

// Scroll right function
export const scrollRight = (scrollRef: any) => {
    scrollRef.current.scrollBy({
        left: 300, // Adjust scroll amount as needed
        behavior: 'smooth'
    });
};
import { Checkbox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dots from '../../assets/icons/dots.png';
import success from '../../assets/icons/success.svg';
import ACCESSLUSSO from '../../assets/images/ACCESSLUSSO.png';
import ChevronRightWhite from '../../assets/images/ChevronRight.png';
import greenTick from '../../assets/images/greenTick.png';
import ChevronRight from '../../assets/images/subscription-chev-right.png';
import Visa from '../../assets/images/Visa.png';
import DrawerRtL from '../../components/common/DrawerRtL';
import CancelPlanPurchase from '../../components/subscriptions/CancelPlanPurchase';
import Button from '../../components/ui/Button';
import Dropdown from '../../components/ui/Dropdown';
import { ICON_ENUM } from '../../constants/icons.constant';
import { CREATOR_PLANS_ENUM, PAYMENT_METHODS_ENUM } from '../../data-center/data';
type FormData = {
    cardNumber: string;
    cardHolderName: string;
    expiry: string;
    cvv: string;
};

type Errors = {
    cardNumber: string;
    cardHolderName: string;
    expiry: string;
    cvv: string;
    saveCard: any;
};
const Purchased = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedActivePlanIndex, setSelectedActivePlanIndex] = useState(null);
    const [selectedUpgradePlanIndex, setSelectedUpgradePlanIndex] =
        useState(null);
    const [formData, setFormData] = useState({
        cardNumber: '',
        cardHolderName: '',
        expiry: '',
        cvv: '',
        saveCard: false,
    });
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();
    const [errors, setErrors] = useState({
        cardNumber: '',
        cardHolderName: '',
        expiry: '',
        cvv: '',
        saveCard: '',
    });

    const [showUpgradePlan, setShowUpgradePlan] = useState(false);
    const [planUpdated, setPlanUpdated] = useState(false);

    const handleUpgradeClick = () => {
        setShowUpgradePlan(true);
    };
    const handleSuccessClick = () => {
        setPlanUpdated(true);
    };

    const handleCancelClick = () => {
        setShowUpgradePlan(false);
    };

    const activePlan = CREATOR_PLANS_ENUM?.filter(plan => plan.active);
    const othersPlan = CREATOR_PLANS_ENUM?.filter(plan => !plan.active);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        validateField(name, value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setIsChecked(checked);

        if (checked) {
            setErrors(prevErrors => ({
                ...prevErrors,
                saveCard: '',
            }));
        }
    };

    const validateField = (fieldName: string, value: string) => {
        const newErrors = { ...errors };

        switch (fieldName) {
            case 'cardNumber':
                if (!value) {
                    newErrors.cardNumber = 'Card number is required';
                } else if (!/^\d{16}$/.test(value)) {
                    newErrors.cardNumber = 'Card number must be 16 digits';
                } else {
                    newErrors.cardNumber = '';
                }
                break;
            case 'cardHolderName':
                if (!value) {
                    newErrors.cardHolderName = 'Card holder name is required';
                } else {
                    newErrors.cardHolderName = '';
                }
                break;
            case 'expiry':
                if (!value) {
                    newErrors.expiry = 'Expiry date is required';
                } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
                    newErrors.expiry = 'Expiry must be in MM/YY format';
                } else {
                    newErrors.expiry = '';
                }
                break;
            case 'cvv':
                if (!value) {
                    newErrors.cvv = 'CVV is required';
                } else if (!/^\d{3}$/.test(value)) {
                    newErrors.cvv = 'CVV must be 3 digits';
                } else {
                    newErrors.cvv = '';
                }
                break;
            case 'saveCard':
                if (!value) {
                    newErrors.saveCard = 'You must check this option';
                } else {
                    newErrors.saveCard = '';
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const validateForm = (): boolean => {
        const newErrors: Errors = {
            cardNumber: '',
            cardHolderName: '',
            expiry: '',
            cvv: '',
            saveCard: '',
        };

        if (!formData.cardNumber) {
            newErrors.cardNumber = 'Card number is required';
        } else if (!/^\d{16}$/.test(formData.cardNumber)) {
            newErrors.cardNumber = 'Card number must be 16 digits';
        }

        if (!formData.cardHolderName) {
            newErrors.cardHolderName = 'Card holder name is required';
        }

        if (!formData.expiry) {
            newErrors.expiry = 'Expiry date is required';
        } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiry)) {
            newErrors.expiry = 'Expiry must be in MM/YY format';
        }

        if (!formData.cvv) {
            newErrors.cvv = 'CVV is required';
        } else if (!/^\d{3}$/.test(formData.cvv)) {
            newErrors.cvv = 'CVV must be 3 digits';
        }
        if (!isChecked) {
            newErrors.saveCard = 'You must agree to save the card details';
        }
        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === '');
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
            alert('Saved');
        }
    };

    const handlePlanSelect = (index: any, isActivePlan: boolean) => {
        if (isActivePlan) {
            setSelectedActivePlanIndex(
                selectedActivePlanIndex === index ? null : index,
            );
        } else {
            setSelectedUpgradePlanIndex(
                selectedUpgradePlanIndex === index ? null : index,
            );
        }
    };

    const handleCardSelect = (card: any) => {
        setSelectedCard(card);
    };

    useEffect(() => {
        if (PAYMENT_METHODS_ENUM?.length > 0) {
            setSelectedCard(PAYMENT_METHODS_ENUM[0] as any);
        }
    }, [PAYMENT_METHODS_ENUM]);

    const payments = [
        {
            date: '10/12',
            paymentMethod: '****9519',
            type: 'Standard (monthly)',
            amount: '$199.00',
        },
        {
            date: '10/12',
            paymentMethod: '****9519',
            type: 'Standard (monthly)',
            amount: '$199.00',
        },
        {
            date: '10/12',
            paymentMethod: '****9519',
            type: 'Standard (monthly)',
            amount: '$199.00',
        },
        {
            date: '10/12',
            paymentMethod: '****9519',
            type: 'Standard (monthly)',
            amount: '$199.00',
        },
        {
            date: '10/12',
            paymentMethod: '****9519',
            type: 'Standard (monthly)',
            amount: '$199.00',
        },

        // Add more objects if needed
    ];

    const planCardRendere = (plan: any, index: any, isActivePlan: any) => {
        const isSelected = isActivePlan
            ? selectedActivePlanIndex === index
            : selectedUpgradePlanIndex === index;
        return (
            <div
                key={plan.title + '_' + index}
                // className="p-[1px] rounded-lg h-full"
                // style={{ background: plan.color }}
                className={`p-[1px] rounded-lg h-full cursor-pointer ${isSelected ? 'border-2 border-[#00F0FB66]' : ''
                    }`}
                style={{
                    background: isSelected ? '#00F0FB66' : plan.color,
                }}
                onClick={() => handlePlanSelect(index, isActivePlan)}
            >
                <div
                    // className="planCard-price-bg grid grid-cols-3 gap-5 p-5 rounded-lg min-h-[calc(100%)]"
                    className={`planCard-price-bg grid grid-cols-3 gap-5 p-5 rounded-lg min-h-[calc(100%)] ${isSelected ? 'text-white' : ''
                        }`}
                >
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col items-center">
                            <span className="text-2xl font-bold uppercase tracking-[0.3em]">
                                {plan.title ?? ''}
                            </span>
                            <span className="text-2xl uppercase tracking-[0.3em] font-light">
                                {plan.subTitle ?? ''}
                            </span>
                            <span className="text-[#FFFFFF80] text-xs uppercase">
                                {plan.planType ?? ''}
                            </span>
                        </div>
                        <div className={`flex flex-col py-2 text-center w-full ${plan.bg}`}>
                            <span className="text-md font-bold">
                                {plan.price === 'Free'
                                    ? plan.price
                                    : `$${plan.price ?? '0.00'}`}
                            </span>
                            <span className="text-[#FFFFFF80] text-xs uppercase">
                                {plan.duration ?? ''}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 col-span-2">
                        {plan.features?.map((feature: any, idx: number) => (
                            <div
                                key={'plan_features_' + index + '_' + idx}
                                className="flex items-center space-x-3"
                            >
                                <div className="h-3 w-3">
                                    <img
                                        src={ICON_ENUM.BLUE_TICK.icon}
                                        alt="tick"
                                        className="h-full w-full"
                                    />
                                </div>
                                <p className="flex flex-wrap">{feature ?? ''}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const paymentTypeCard = (card: any) => {
        const isSelected = selectedCard === card;
        return (
            <div
                onClick={() => handleCardSelect(card)}
                className="flex-1 flex flex-row cursor-pointer"
                style={{
                    padding: 12,
                    // background: '#00000033',
                    color: isSelected ? '#00F0FB66' : '',
                    background: isSelected ? '#00F0FB1F' : '#00000033',
                    border: `1px solid ${isSelected ? '#00F0FB66' : '#403076'}`,
                    borderRadius: 8,
                }}
            >
                <div className="mr-1">
                    <img src={card?.icon} alt="" />
                </div>
                <div>
                    <span
                        style={{
                            color: isSelected ? '#00F0FB' : 'white',
                        }}
                    >
                        {card?.name}
                    </span>
                </div>
            </div>
        );
    };
    const monthOptions = [
        { value: 'Jan', label: 'January' },
        { value: 'Feb', label: 'Febrary' },
        { value: 'Mar', label: 'March' },
        { value: 'Apr', label: 'April' },
        { value: 'May', label: 'May' },
    ];
    return (
        <>
            <div className="text-white font-bold text-[24px] mb-8">
                <span className="bg-gradient-to-r from-[#985FFF] to-[#FF99EF] bg-clip-text text-transparent">
                    Subscription
                </span>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 space-x-2">
                {/* Left Column */}
                <div className="lg:col-span-3 space-y-5">
                    <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                        <span>Current Plan</span>

                        <div>
                            <DrawerRtL
                                closeId="close-update-plan-drawer"
                                handler={
                                    <div className="flex items-center space-x-3 cursor-pointer">
                                        <span className="text-white font-semibold text-[18px]">
                                            Update Plan
                                        </span>
                                        <img
                                            src={ChevronRight}
                                            alt="Chevron Right"
                                            className="w-2 h-3"
                                        />
                                    </div>
                                }
                            >
                                <div className="menu dev-subscription-sider min-h-full w-2/5 text-white px-10 py-16 space-y-10">
                                    {!planUpdated && !showUpgradePlan && (
                                        <div>
                                            {activePlan?.length && (
                                                <div className="space-y-5">
                                                    <span className="text-xl">Current Plan</span>
                                                    {activePlan?.map((plan, index) =>
                                                        planCardRendere(plan, index, true),
                                                    )}
                                                </div>
                                            )}
                                            <div className="space-y-5 mt-5">
                                                <span className="text-xl">Upgrade Plan</span>
                                                {othersPlan?.map((plan, index) =>
                                                    planCardRendere(plan, index, false),
                                                )}
                                            </div>
                                            <div className="flex justify-center items-center gap-3 mt-8">
                                                <div className="cursor-pointer border-2 border-[#7D3CF3] font-bold px-10 py-2 rounded-full">
                                                    <span
                                                        id="close-update-plan-drawer"
                                                        className="cursor-pointer py-2 px-12 w-full inline-block"
                                                        onClick={handleCancelClick}
                                                    >
                                                        Cancel
                                                    </span>
                                                </div>
                                                <div className="bg-gradient-vertical font-bold px-10 py-2 rounded-full">
                                                    <Button
                                                        id="close-update-plan-drawer"
                                                        label="Upgrade Plan"
                                                        onClick={handleUpgradeClick}
                                                        icon={{
                                                            position: 'start',
                                                            component: (
                                                                <img
                                                                    src={ICON_ENUM.PLUS_WITH_CIRCLE.icon}
                                                                    className="w-5 h-5"
                                                                    alt="plus"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!planUpdated && showUpgradePlan && (
                                        <div className="space-y-5 flex flex-col">
                                            <span className="text-xl">Upgrade Plan</span>
                                            <span className="font-medium text-sm text-[#B1ADCD]">
                                                Selected plan
                                            </span>
                                            {activePlan?.map((plan, index) =>
                                                planCardRendere(plan, index, true),
                                            )}
                                            <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                                                <span className="text-[#B1ADCD]">Card details</span>
                                                <div className="flex items-center space-x-3 cursor-pointer">
                                                    <span className="text-white font-semibold text-[18px]">
                                                        Change Card
                                                    </span>
                                                    <img
                                                        src={ChevronRight}
                                                        alt="Chevron Right"
                                                        className="w-2 h-3"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    border: '1px solid #A768FD33',
                                                    borderRadius: 8,
                                                    background: 'rgba(4, 4, 4, 0.20)',
                                                }}
                                            >
                                                <div className="flex flex-row justify-between items-center px-4 py-8 font-bold">
                                                    <span className="text-white text-center ">
                                                        <img src={Visa} alt="visa" />
                                                    </span>
                                                    <span className="text-white text-center">
                                                        1256 5862 2560 0000
                                                    </span>
                                                    <span className="text-white text-center">14/27</span>
                                                    <span className="text-white text-center ">
                                                        <img src={dots} alt="" />
                                                    </span>
                                                    <span className="text-white text-center ">
                                                        <img src={success} alt="" className="w-5 h-5" />
                                                    </span>
                                                </div>
                                            </div>

                                            <span className="text-[#B1ADCD]">Order Summary</span>
                                            <hr className="border-[#B1ADCD]" />
                                            <span className="font-bold text-[#9551F6] text-lg">
                                                ELITE LUSSO
                                            </span>
                                            <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                                                <span className="text-white">Monthly</span>
                                                <div className="flex items-center space-x-3 cursor-pointer">
                                                    <span className="text-white text-[18px]">$4,299</span>
                                                </div>
                                            </div>
                                            <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                                                <span className="text-[#B1ADCD] text-base">
                                                    Promotion (50% off for 2 months)
                                                </span>
                                                <div className="flex items-center space-x-3 cursor-pointer">
                                                    <span className="text-white text-[14px]">-200$</span>
                                                </div>
                                            </div>
                                            <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                                                <span className="text-white">Tax</span>
                                                <div className="flex items-center space-x-3 cursor-pointer">
                                                    <span className="text-white text-[18px]">$0</span>
                                                </div>
                                            </div>
                                            <hr className="border-[#B1ADCD]" />
                                            <div className="text-white font-semibold text-[18px] flex justify-between items-center">
                                                <span className="text-white">SubTotal</span>
                                                <div className="flex items-center space-x-3 cursor-pointer">
                                                    <span className="text-[B1ADCD] font-semibold text-[18px]">
                                                        $4290
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex justify-center items-center gap-3 mt-8">
                                                <div className="cursor-pointer border-2 border-[#7D3CF3] font-bold px-10 py-2 rounded-full">
                                                    <span
                                                        id="close-update-plan-drawer"
                                                        className="cursor-pointer py-2 px-12 w-full inline-block"
                                                        onClick={handleCancelClick}
                                                    >
                                                        Cancel
                                                    </span>
                                                </div>
                                                <div className="bg-gradient-vertical font-bold px-10 py-2 rounded-full">
                                                    <Button
                                                        id="close-update-plan-drawer"
                                                        label="Upgrade Plan"
                                                        onClick={handleSuccessClick}
                                                        icon={{
                                                            position: 'start',
                                                            component: (
                                                                <img
                                                                    src={ICON_ENUM.PLUS_WITH_CIRCLE.icon}
                                                                    className="w-5 h-5"
                                                                    alt="plus"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {planUpdated && (
                                        <div className="flex flex-col justify-center items-center space-y-6 min-h-screen">
                                            <img src={success} alt="success" />
                                            <h2 className="text-lg font-bold text-center capitalize">
                                                Your plan has been upgraded to <br />{' '}
                                                <span className="font-bold text-2xl text-center text-white uppercase">
                                                    ELITE LUSSO!
                                                </span>
                                            </h2>

                                            <div>
                                                <Button
                                                    id="close-view-history-drawer"
                                                    label="Go to Dashboard"
                                                    className="bg-gradient-vertical font-bold rounded-full px-2 py-2"
                                                    onClick={() => navigate('/dev/dashboard')}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </DrawerRtL>
                        </div>
                    </div>

                    <div className="card-bg-dev px-5 py-8 rounded-lg space-y-10">
                        <span
                            className="flex items-center justify-center rounded-full p-3 w-1/3 text-white"
                            style={{
                                background:
                                    'linear-gradient(115.33deg, #006EF8 20.95%, #212CB1 72.86%, #2B189C 88.07%)',
                            }}
                        >
                            Plan active since: May 1, 2024
                        </span>
                        <div className="flex items-center justify-between">
                            <img src={ACCESSLUSSO} alt="ACCESSLUSSO" />
                            <div className="text-right">
                                <span
                                    className="text-2xl font-semibold"
                                    style={{ color: '#5B97FF' }}
                                >
                                    $99.99
                                </span>
                                <span className="text-2xl " style={{ color: 'white' }}>
                                    /mon
                                </span>
                                <div className="text-sm" style={{ color: 'white' }}>
                                    Free 7-day trial
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Plan Benefits and Payment History Section */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 space-x-2">
                        <div className="space-y-5">
                            <span className="text-white text-[24px]">Plan Benefits</span>
                            <div
                                className="rounded-lg p-[1px] h-full"
                                style={{
                                    background:
                                        'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
                                }}
                            >
                                <div className="card-bg-dev-border-none px-4 py-6 rounded-lg space-y-8 h-full">
                                    <img src={ACCESSLUSSO} alt="ACCESSLUSSO" />
                                    <hr style={{ color: 'grey', borderColor: 'grey' }} />
                                    <div className="space-y-3">
                                        <div className="flex items-center p-2">
                                            <img src={greenTick} alt="green tick" className="mr-3" />
                                            <span className="text-[sm] text-white">
                                                Includes 50 digital products and completely customizable
                                            </span>
                                        </div>
                                        <div className="flex items-center p-2">
                                            <img src={greenTick} alt="green tick" className="mr-3" />
                                            <span className="text-[sm] text-white">
                                                Perfect for small projects and individual use.
                                            </span>
                                        </div>
                                        <div className="flex items-center p-2">
                                            <img src={greenTick} alt="green tick" className="mr-3" />
                                            <span className="text-[sm] text-white">
                                                Free updates for 3 months.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-2 space-y-5">
                            <span className="text-white text-[24px]">Payment History</span>
                            <div
                                className="rounded-lg p-[1px] h-full"
                                style={{
                                    background:
                                        'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
                                }}
                            >
                                <div className="card-bg-dev-border-none flex flex-col justify-between p-5 rounded-lg h-full space-y-3">
                                    <div
                                        className="subscriptions max-h-full"
                                        style={{ overflow: 'auto' }}
                                    >
                                        <div
                                            className="flex flex-row justify-between items-center p-4 font-bold"
                                            style={{
                                                background:
                                                    'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)',
                                            }}
                                        >
                                            <span className="text-white flex-1 text-center ">
                                                Date
                                            </span>
                                            <span className="text-white flex-1 text-center ">
                                                Payment Method
                                            </span>
                                            <span className="text-white flex-1 text-center ">
                                                Payment Type
                                            </span>
                                            <span className="text-white flex-1 text-center ">
                                                Amount
                                            </span>
                                        </div>

                                        {payments?.map((payment, index) => (
                                            <div
                                                key={index}
                                                className="p-[1px]"
                                                style={{
                                                    background:
                                                        'linear-gradient(90deg, #8423F4 0%, rgba(255, 153, 239, 0.86) 100%)',
                                                }}
                                            >
                                                <div
                                                    className="flex flex-row p-4 justify-between"
                                                    style={{
                                                        background:
                                                            'linear-gradient(270deg, #2E246C 0%, #2E246C 56.9%, #271F56 100%)',
                                                    }}
                                                >
                                                    <span className="flex-1 text-[#FFF] font-normal text-[13px] text-center">
                                                        {payment.date}
                                                    </span>
                                                    <span className="flex-1 text-[#FFF] font-normal text-[13px] text-center">
                                                        <div className="flex items-center justify-center">
                                                            <img
                                                                src={Visa} // Update with your actual path
                                                                alt="Visa"
                                                                className="mr-2"
                                                            />
                                                            <span className="text-white font-semibold">
                                                                {payment.paymentMethod}
                                                            </span>
                                                        </div>
                                                    </span>
                                                    <span className="flex-1 text-[#FFF] font-normal text-[13px] text-center pl-6">
                                                        {payment.type}
                                                    </span>
                                                    <span className="flex-1 text-[#FFF] font-normal text-[13px] text-center">
                                                        {payment.amount}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <DrawerRtL
                                        closeId="close-view-history-drawer"
                                        handler={
                                            <div className="flex justify-between items-center font-bold w-full border border-[#6C8CFF80] rounded-lg bg-[#00000033] p-5">
                                                <span className="text-white">View full history</span>
                                                <img
                                                    src={ChevronRightWhite}
                                                    alt="Chevron"
                                                    className="w-[10px] h-[12px]"
                                                />
                                            </div>
                                        }
                                    >
                                        <div className="menu dev-subscription-sider min-h-full w-2/5 text-white px-10 py-16 space-y-10">
                                            <div className="lg:col-span-2 space-y-5">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <span className="text-white text-[24px] text-center">
                                                            Payment History
                                                        </span>
                                                    </div>
                                                    <div className="">
                                                        <Dropdown
                                                            // label="Month"
                                                            name="month"
                                                            // value={stateOptions?.find((i: any) => i?.label === formData?.state)}
                                                            options={monthOptions}
                                                            placeholder="Select Month"
                                                            borderRadius={0}
                                                            borderColor="var(--outline, #6C8CFF80)"
                                                        // required
                                                        // error={validation?.state?.error ?? false}
                                                        // errorMessage={validation?.state?.errorMessage ?? ''}
                                                        // onChange={onStateChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="rounded-lg p-[1px] h-full"
                                                // style={{
                                                //     background:
                                                //         'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
                                                // }}
                                                >
                                                    <div
                                                        className=" flex flex-col justify-between rounded-lg h-full space-y-3"
                                                        style={{
                                                            backgroundColor:
                                                                'linear-gradient(to right, #25204AE5, #18142E80, #16132B80, #2D246CE5)',
                                                        }}
                                                    >
                                                        <div
                                                            className="subscriptions max-h-full rounded-md"
                                                            style={{ overflow: 'auto' }}
                                                        >
                                                            <div
                                                                className="flex flex-row justify-between items-center p-4 font-bold"
                                                                style={{
                                                                    background:
                                                                        'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)',
                                                                }}
                                                            >
                                                                <span className="text-white  uppercase whitespace-nowrap">
                                                                    Date
                                                                </span>
                                                                <span className="text-white  uppercase whitespace-nowrap">
                                                                    Payment Method
                                                                </span>
                                                                <span className="text-white f uppercase whitespace-nowrap">
                                                                    Payment Type
                                                                </span>
                                                                <span className="text-white  uppercase whitespace-nowrap">
                                                                    Amount
                                                                </span>
                                                            </div>

                                                            {payments?.map((payment, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="p-[1px]"
                                                                    style={{
                                                                        borderBottom: '1px solid transparent',
                                                                        borderImage:
                                                                            'linear-gradient(90deg, #8423F4 0%, rgba(255, 153, 239, 0.86) 100%) 1',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="flex flex-row p-4 justify-between"
                                                                        style={{
                                                                            background:
                                                                                'linear-gradient(270deg, #2E246C 0%, #2E246C 56.9%, #271F56 100%)',
                                                                        }}
                                                                    >
                                                                        <span className=" text-[#FFF] font-normal text-[13px]">
                                                                            {payment.date}
                                                                        </span>
                                                                        <span className=" text-[#FFF] font-normal text-[13px]">
                                                                            <div className="flex items-center justify-center">
                                                                                <img
                                                                                    src={Visa} // Update with your actual path
                                                                                    alt="Visa"
                                                                                    className="mr-2"
                                                                                />
                                                                                <span className="text-white font-semibold">
                                                                                    {payment.paymentMethod}
                                                                                </span>
                                                                            </div>
                                                                        </span>
                                                                        <span className=" text-[#FFF] font-normal text-[13px] pl-6">
                                                                            {payment.type}
                                                                        </span>
                                                                        <span className=" text-[#FFF] font-normal text-[13px]">
                                                                            {payment.amount}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-center items-center gap-3">
                                                <div className="bg-gradient-vertical font-bold px-16 rounded-full py-2">
                                                    <Button
                                                        id="close-view-history-drawer"
                                                        label="Export History"
                                                        className=""
                                                    />
                                                </div>
                                                <div className="cursor-pointer border-2 border-[#7D3CF3] font-bold  px-12 py-2 rounded-full">
                                                    <span
                                                        id="close-view-history-drawer"
                                                        className="cursor-pointer py-2 px-12 w-full inline-block"
                                                    >
                                                        Cancel
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </DrawerRtL>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column */}
                <div className="">
                    <div className="text-white font-semibold text-[18px] mb-4">
                        Payment Method
                    </div>
                    <div
                        className="rounded-lg p-[1px]"
                        style={{
                            background:
                                'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
                        }}
                    >
                        <div className="profile-card card-bg-dev-border-none p-4 rounded-lg">
                            <div className="flex items-center mt-6">
                                <img src={Visa} alt="Visa" className="w-[64px] h-[18px] mr-3" />
                                <span className="text-white font-semibold">****9519</span>
                            </div>
                            <div className="flex items-center mt-8">
                                <span className="text-[#666F8D] font-semibold">
                                    Name on card
                                </span>
                                <span className="text-white font-semibold ml-3">
                                    Robert Downey
                                </span>
                            </div>
                            <div className="flex items-center mt-8">
                                <span className="text-[#666F8D] font-semibold">
                                    Expiration date
                                </span>
                                <span className="text-white font-semibold ml-3">5/27</span>
                            </div>
                            <DrawerRtL
                                closeId="close-payment-method-drawer"
                                handler={
                                    <span className="field flex justify-between items-center w-full border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8 p-3">
                                        <span className="text-white pl-4">Change Card</span>
                                        <img
                                            src={ChevronRightWhite}
                                            alt="Chevron"
                                            className="w-[10px] h-[12px]"
                                        />
                                    </span>
                                }
                            >
                                <form
                                    className="menu dev-subscription-sider min-h-full w-2/5 text-white px-10 py-16 space-y-8"
                                    onSubmit={handleSubmit}
                                >
                                    {PAYMENT_METHODS_ENUM?.length && (
                                        <div className="space-y-5">
                                            <span className="text-xl">Change Card</span>
                                            <div className="grid grid-cols-2 space-3">
                                                {PAYMENT_METHODS_ENUM?.map(card =>
                                                    paymentTypeCard(card),
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="flex flex-col p-4"
                                        style={{
                                            border: '1px solid #A768FD33',
                                            borderRadius: 8,
                                            background: 'rgba(4, 4, 4, 0.20)',
                                        }}
                                    >
                                        <div className="text-white font-normal text-[16px] mb-2">
                                            <span>Enter Card Details</span>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="cardNumber"
                                                placeholder="Enter card number"
                                                className="ac-frm-input rounded-pill badge h-[50px] mb-2"
                                                value={formData.cardNumber}
                                                onChange={handleInputChange}
                                                style={{
                                                    borderRadius: 50,
                                                    border: '1px solid #A768FD',
                                                    background: 'rgba(4, 4, 4, 0.20)',
                                                    marginTop: 10,
                                                }}
                                            />
                                            {errors.cardNumber && (
                                                <span className="text-red-500">
                                                    {errors.cardNumber}
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Card holder name"
                                                name="cardHolderName"
                                                className="ac-frm-input rounded-pill badge h-[50px] mb-2"
                                                value={formData.cardHolderName}
                                                onChange={handleInputChange}
                                                style={{
                                                    borderRadius: 50,
                                                    border: '1px solid #A768FD',
                                                    background: 'rgba(4, 4, 4, 0.20)',
                                                    marginTop: 10,
                                                }}
                                            />
                                            {errors?.cardHolderName && (
                                                <span className="text-red-500">
                                                    {errors?.cardHolderName}
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex flex-row gap-3">
                                            <div className="flex-1">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Exp"
                                                    name="expiry"
                                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                                    value={formData?.expiry}
                                                    onChange={handleInputChange}
                                                    style={{
                                                        borderRadius: 50,
                                                        border: '1px solid #A768FD',
                                                        background: 'rgba(4, 4, 4, 0.20)',
                                                        marginTop: 10,
                                                    }}
                                                />
                                                {errors?.expiry && (
                                                    <span className="text-red-500 mt-1">
                                                        {errors?.expiry}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="CVV"
                                                    name="cvv"
                                                    className="ac-frm-input rounded-pill badge h-[50px]"
                                                    value={formData?.cvv}
                                                    onChange={handleInputChange}
                                                    style={{
                                                        borderRadius: 50,
                                                        border: '1px solid #A768FD',
                                                        background: 'rgba(4, 4, 4, 0.20)',
                                                        marginTop: 10,
                                                    }}
                                                />
                                                {errors?.cvv && (
                                                    <span className="text-red-500 mt-1">
                                                        {errors?.cvv}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex space-x-4">
                                        <div>
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                color="rgba(188, 89, 241, 1)"
                                                styles={{
                                                    root: {
                                                        '&.mantine-Checkbox-root input:checked + .mantine-Checkbox-label':
                                                        {
                                                            background:
                                                                'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                                                            borderRadius: '4px',
                                                            padding: '4px',
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Save Updated Card details</span>
                                        </div>
                                        {errors.saveCard && (
                                            <span className="text-red-500 ">{errors.saveCard}</span>
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center gap-3">
                                        <div className="cursor-pointer border-2 border-[#7D3CF3] font-bold  px-12 py-2 rounded-full">
                                            <span
                                                id="close-payment-method-drawer"
                                                className="cursor-pointer py-2 px-12 w-full inline-block"
                                            // onClick={handleNavigation}
                                            >
                                                Cancel
                                            </span>
                                        </div>

                                        <div className="bg-gradient-vertical font-bold px-16 rounded-full py-2">
                                            <Button
                                                id="close-payment-method-drawer"
                                                label="Change Card"
                                                className=""
                                                type="submit"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </DrawerRtL>
                            <div></div>
                        </div>
                    </div>

                    <div className="text-white text-[24px] mb-4 mt-4">Next Renewal</div>
                    <div
                        className="rounded-lg p-[1px]"
                        style={{
                            background:
                                'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
                        }}
                    >
                        <div className="profile-card card-bg-dev-border-none p-4 rounded-lg">
                            <div className="text-white p-1 text-[36px]">$199</div>
                            <div className="flex items-center mt-8">
                                <span className="text-[#666F8D] font-semibold">Plan type</span>
                                <span className="text-white font-semibold ml-3">
                                    Standard (monthly)
                                </span>
                            </div>
                            <div className="flex items-center mt-8">
                                <span className="text-[#666F8D] font-semibold">
                                    Next bill date
                                </span>
                                <span className="text-white font-semibold ml-3">
                                    Jun 15, 2024
                                </span>
                            </div>
                            <DrawerRtL
                                closeId="close-cancel-plan-drawer"
                                handler={
                                    <span className="field flex justify-between items-center w-full border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8 p-3">
                                        <span className="text-white pl-4">Cancel Plan</span>
                                        <img
                                            src={ChevronRightWhite}
                                            alt="Chevron"
                                            className="w-[10px] h-[12px]"
                                        />
                                    </span>
                                }
                            >
                                <div className="menu dev-subscription-sider min-h-full w-2/5 text-white px-10 py-16 space-y-8">
                                    <CancelPlanPurchase />
                                </div>
                            </DrawerRtL>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Purchased;

// import { useNavigate } from 'react-router-dom';
// import ACCESSLUSSO from '../../assets/images/ACCESSLUSSO.png';
// import ChevronRightWhite from '../../assets/images/ChevronRight.png';
// import greenTick from '../../assets/images/greenTick.png';
// import ChevronRight from '../../assets/images/subscription-chev-right.png';
// import Visa from '../../assets/images/Visa.png';

// const Purchased = () => {
//     const navigate = useNavigate();
//     const payments = [
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         {
//             date: "10/12",
//             paymentMethod: "****9519",
//             type: "Standard (monthly)",
//             amount: "$199.00",
//         },
//         // {
//         //     date: "10/12",
//         //     paymentMethod: "****9519",
//         //     type: "Standard (monthly)",
//         //     amount: "$199.00",
//         // },
//         // {
//         //     date: "10/12",
//         //     paymentMethod: "****9519",
//         //     type: "Standard (monthly)",
//         //     amount: "$199.00",
//         // },

//         // Add more objects if needed
//     ];
//     return (
//         <>
//             <div className='text-white font-bold text-[24px] mb-8'>
//                 <span className='bg-gradient-to-r from-[#985FFF] to-[#FF99EF] bg-clip-text text-transparent'>
//                     Subscription
//                 </span>
//             </div>

//             <div className='flex space-x-4'>
//                 {/* Left Column */}
//                 <div>
//                     <div className='mb-8'>
//                         <div className='text-white font-semibold text-[18px] mb-4 flex justify-between items-center'>
//                             <span>Current Plan</span>

//                             {/* New label with chevron */}
//                             <div className='pr-2 flex items-center space-x-2 cursor-pointer'>
//                                 <span className='text-white font-semibold text-[18px]'>
//                                     Update Plan
//                                 </span>
//                                 <img
//                                     src={ChevronRight} // Update with your actual path
//                                     alt='Chevron Right'
//                                     className='w-2 h-3'
//                                 />
//                             </div>
//                         </div>

//                         <div className="w-full max-w-[865px] h-[199px] border-gradient p-4 rounded-lg">
//                             <div className="w-full max-w-[830px] h-[150px] mt-2 p-4 rounded-lg">
//                                 <div className="w-full max-w-[253px] h-[44px] flex items-center justify-center"
//                                     style={{
//                                         background: 'linear-gradient(115.33deg, #006EF8 20.95%, #212CB1 72.86%, #2B189C 88.07%)',
//                                         borderRadius: '20px',
//                                     }}>
//                                     <span className='text-white'>Plan active since: May 1, 2024</span>
//                                 </div>
//                                 <div className="w-full max-w-[800px] h-[66px] mt-8 flex flex-wrap items-center justify-between p-4 rounded-lg">
//                                     <img
//                                         src={ACCESSLUSSO} // Update with your actual path
//                                         alt='ACCESSLUSSO'
//                                         className='w-[171px] h-[50px]'
//                                     />
//                                     <div className="text-right">
//                                         <span className="text-2xl font-semibold" style={{ color: '#5B97FF' }}>$99.99</span>
//                                         <span className="text-2xl" style={{ color: 'white' }}>/mon</span>
//                                         <div className="text-sm" style={{ color: 'white' }}>Free 7-day trial</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Plan Benefits and Payment History Section */}
//                     <div className='flex space-x-2'>
//                         <div className='space-y-8'>
//                             <div className='text-white text-[24px] mb-6'>
//                                 <span>Plan Benefits</span>
//                             </div>
//                             <div className="w-full max-w-[290px] h-[545px] border-gradient p-4 rounded-lg">
//                                 <img
//                                     src={ACCESSLUSSO} // Update with your actual path
//                                     alt='ACCESSLUSSO'
//                                     className='w-[171px] h-[50px] mt-2'
//                                 />
//                                 <hr className="mt-6" style={{ color: 'grey', borderColor: 'grey' }} />
//                                 <div className='mt-12 w-[260px] h-[220px]'>
//                                     <div className="w-full max-w-[300px] h-[70px] flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3 mb-6"
//                                         />
//                                         <span className="text-[sm] text-white">Includes 50 digital products and completely customizable</span>
//                                     </div>
//                                     <div className="w-full max-w-[300px] h-[70px]  flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3 mb-6"
//                                         />
//                                         <span className="text-[sm] text-white">Perfect for small projects and individual use.</span>
//                                     </div>
//                                     <div className="w-full max-w-[300px] h-[70px]  flex items-center p-2">
//                                         <img
//                                             src={greenTick} // Update with your actual path
//                                             alt="green tick"
//                                             className="w-[14px] h-[8px] mr-3"
//                                         />
//                                         <span className="text-[sm] text-white">Free updates for 3 months.</span>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>

//                         <div className='space-y-8'>
//                             <div className='text-white text-[24px] mb-6'>
//                                 <span>Payment History</span>
//                             </div>
//                             <div className="w-full max-w-[570px] h-[545px] border-gradient p-4 rounded-lg">

//                                 <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 400 }}>
//                                     <div
//                                         className='h-[40px] flex flex-row justify-between items-center px-6'
//                                         style={{
//                                             background: 'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)'
//                                         }}
//                                     >
//                                         <span className='text-white flex-1 text-center '>Date</span>
//                                         <span className='text-white flex-1 text-center '>Payment Method</span>
//                                         <span className='text-white flex-1 text-center '>Payment Type</span>
//                                         <span className='text-white flex-1 text-center '>Amount</span>
//                                     </div>

//                                     {payments.map((payment, index) => (
//                                         <div key={index}>
//                                             <div
//                                                 className='flex flex-row px-4 py-6 justify-between'
//                                                 style={{
//                                                     background: 'linear-gradient(270deg, #2E246C 0%, #2E246C 56.9%, #271F56 100%)',
//                                                     borderRadius: '5px', // Optional: for rounded corners
//                                                 }}
//                                             >
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.date}</span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>
//                                                     <div className="flex items-center justify-center">
//                                                         <img
//                                                             src={Visa} // Update with your actual path
//                                                             alt="Visa"
//                                                             className="w-full max-w-[64px] min-w-[32px] h-auto mr-2"
//                                                         />

//                                                         <span className="text-white font-semibold">{payment.paymentMethod}</span>
//                                                     </div>
//                                                 </span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center pl-6'>{payment.type}</span>
//                                                 <span className='flex-1 text-[#FFF] font-normal text-[13px] text-center'>{payment.amount}</span>
//                                             </div>
//                                             <div style={{ border: '0.1px gray solid' }}></div>
//                                         </div>
//                                     ))}

//                                 </div>

//                                 <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                                     <span className="text-white pl-4">View full history</span>
//                                     <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>

//                 {/* Right Column */}
//                 <div>
//                     <div className='text-white font-semibold text-[18px] mb-4'>
//                         Payment Method
//                     </div>
//                     <div className="profile-card w-[330px] h-[396px] border-gradient p-4 rounded-lg">
//                         <div className="flex items-center mt-6">
//                             <img
//                                 src={Visa} // Update with your actual path
//                                 alt="Visa"
//                                 className="w-[64px] h-[18px] mr-3"
//                             />
//                             <span className="text-white font-semibold">****9519</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Name on card</span>
//                             <span className="text-white font-semibold ml-3">Robert Downey</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Expiration date</span>
//                             <span className="text-white font-semibold ml-3">5/27</span>
//                         </div>
//                         <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                             <span className="text-white pl-4">Change Card</span>
//                             <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                         </div>
//                     </div>

//                     <div className='text-white text-[24px] mb-4 mt-4'>
//                         Next Renewal
//                     </div>
//                     <div className="profile-card w-[330px] h-[376px] border-gradient p-4 rounded-lg">
//                         <div className='text-white p-1 text-[36px]'>$199</div>
//                         <div className="flex items-center mt-8">
//                             <span className="text-[#666F8D] font-semibold">Plan type</span>
//                             <span className="text-white font-semibold ml-3">Standard (monthly)</span>
//                         </div>
//                         <div className="flex items-center mt-8">

//                             <span className="text-[#666F8D] font-semibold">Next bill date</span>
//                             <span className="text-white font-semibold ml-3">Jun 15, 2024</span>
//                         </div>
//                         <div className="field flex justify-between items-center w-full h-[70px] border border-[#6C8CFF80] rounded-lg bg-[#00000033] mt-8">
//                             <span className="text-white pl-4">Cancel Plan </span>
//                             <img src={ChevronRightWhite} alt="Chevron" className="w-[10px] h-[12px] mr-4" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>

//     );
// };

// export default Purchased;

// import { useNavigate } from 'react-router-dom';
// import CardIcon from '../../assets/images/icons/card-icon.svg';
// import CardTypeIcon from '../../assets/images/icons/card-type.svg';
// import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
// import TickIcon from '../../assets/images/tick-icon.svg';

// const Purchased = () => {
//     const navigate = useNavigate();
//     return (
//         <div className="container price-background-banner">
//             <div className="left"></div>
//             <div className="right">
//                 <div className='text-white font-bold text-[20px]'>
//                     <span>Your Plan Details</span>
//                 </div>
//                 <div>
//                     <div className="horizontal-divider-light mt-4 mb-4"></div>
//                 </div>
//                 <div className="flex flex-col gap-y-4">
//                     <div className="purchased-card px-4 py-4">
//                         <div >
//                             <span className="text-white px-3 py-2" style={{ background: '#3B3396', fontSize: '0.8rem', borderRadius: 16 }}>
//                                 CURRENT PLAN
//                             </span>
//                         </div>
//                         <div className="flex flex-row justify-between items-center current-plan-detail-row">
//                             <div className="flex flex-col py-4">
//                                 <span className="font-bold text-[#FF56E5]" style={{ fontSize: '1.5rem' }}>
//                                     Standard
//                                 </span>
//                                 <span className="font-normal text-[#FFF]" style={{ fontSize: '0.8rem' }}>
//                                     Standard monthly subscription plan active since: May1, 2024
//                                 </span>
//                             </div>
//                             <div className='current-plan-btn-row flex flex-row justify-center items-center gap-2'>
//                                 <button
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #FFF',
//                                         textTransform: 'capitalize',
//                                         paddingLeft: 12,
//                                         paddingRight: 12
//                                     }}
//                                 >
//                                     Cancel Plan
//                                 </button>
//                                 <button
//                                     onClick={() => {
//                                         navigate('/dev/pricing')
//                                     }}
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #A768FD',
//                                         background: 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
//                                         textTransform: 'capitalize',
//                                         paddingLeft: 12,
//                                         paddingRight: 12
//                                     }}
//                                 >
//                                     Upgrade
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="current-next-row flex flex-row gap-x-2">
//                         <div className="flex-1 purchased-card px-4 py-4">
//                             <div>
//                                 <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Current Plans Benefits
//                                 </span>
//                             </div>
//                             <div className='flex flex-row mt-4'>
//                                 <div className="flex-1 flex flex-col gap-y-2">
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div className="flex-1 flex flex-col gap-y-2">
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                     <div className='flex flex-row gap-x-2'>
//                                         <span>
//                                             <img src={TickIcon} alt='' />
//                                         </span>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                             Feature X
//                                         </span>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="flex-1 purchased-card px-4 py-4">
//                             <div className='flex flex-row justify-between items-center'>
//                                 <div>
//                                     <div>
//                                         <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
//                                             Current Plans Benefits
//                                         </span>
//                                     </div>
//                                     <div>
//                                         <span className="text-white font-normal" style={{ fontSize: '1.5rem' }}>
//                                             $ 199.00
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div style={{ backgroundColor: '#F927D9', paddingLeft: 12, paddingRight: 12, paddingBottom: 16, paddingTop: 16, borderRadius: '50%' }}>
//                                     <img src={CardIcon} alt='' />
//                                 </div>
//                             </div>
//                             <div className='flex flex-col justify-between mt-3'>
//                                 <div className='flex flex-row'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Plan type:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Standard (monthly)
//                                     </span>
//                                 </div>
//                                 <div className='flex flex-row mt-1'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Next bill date:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Jun 15, 2024
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="payment-method-row flex flex-row gap-x-2">
//                         <div className="purchased-card px-4 py-4 flex flex-col justify-between" style={{ width: '30%' }}>
//                             <div>
//                                 <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Payment Method
//                                 </span>
//                             </div>
//                             <div className='flex flex-row items-center justify-between'>
//                                 <div>
//                                     <img src={CardTypeIcon} alt='' />
//                                 </div>
//                                 <div>
//                                     <span className='text-white'>**** 9519</span>
//                                 </div>
//                             </div>
//                             <div className='flex flex-col justify-between mt-3'>
//                                 <div className='flex flex-row items-center'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Name on Card:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         Robert Downey
//                                     </span>
//                                 </div>
//                                 <div className='flex flex-row mt-1 items-center'>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
//                                         Expiration date:
//                                     </span>
//                                     <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
//                                         5/27
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className='change-delete-btn-row flex flex-row items-center gap-1 mt-1'>
//                                 <button
//                                     className="saveContinue"
//                                     style={{
//                                         borderRadius: 8,
//                                         border: '1px solid #FFF',
//                                         textTransform: 'capitalize'
//                                     }}
//                                 >
//                                     Change
//                                 </button>
//                                 <div style={{ backgroundColor: '#FFF', padding: 8, borderRadius: '50%' }}>
//                                     <img src={DeleteIcon} alt='' />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex-1 purchased-card">
//                             <div className='px-4 py-4'>
//                                 <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
//                                     Payment Method
//                                 </span>
//                             </div>
//                             <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 300 }}>
//                                 <div className='flex flex-row px-4'>
//                                     <span className='flex-1 text-white'>Date</span>
//                                     <span className='flex-1 text-white'>Payment Type</span>
//                                     <span className='flex-1 text-white'>Amount</span>
//                                 </div>
//                                 <div style={{ border: '1px #FFF solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                                 <div style={{ border: '0.1px gray solid' }}></div>
//                                 <div className='flex flex-row px-4 py-1'>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
//                                     <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Purchased;

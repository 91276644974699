import {configureStore} from '@reduxjs/toolkit';
import exploreReducer from './explore/exploreSlice';
import socialAnalyticsReducer from './socialAnalytics/socialAnalyticSlice';

export const store = configureStore({
  reducer: {
    explore: exploreReducer,
    socialAnalytics: socialAnalyticsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

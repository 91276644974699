export const VisitorData = [
    {
        label:"January",
        count: 60000
    },
    {
        label:"February",
        count: 100000
    },
    {
        label:"March",
        count: 120000
    },
    {
        label:"April",
        count: 160000
    },
    {
        label:"May",
        count: 80000
    },
    {
        label:"June",
        count: 70000
    },
    {
        label:"July",
        count: 50000
    },
    {
        label:"Augest",
        count: 30000
    },
    {
        label:"September",
        count: 180000
    },
    {
        label:"October",
        count: 200000
    },
    {
        label:"November",
        count: 212000
    },
    {
        label:"December",
        count: 240000
    },
]


export const PieChartData = [
    {
        gender:"male",
        count:"243"
    },
    {
        gender:"female",
        count:"98"
    }
]

export const DoughNutChartData = [
    {
        device:"mobile",
        count:"243"
    },
    {
        device:"desktop",
        count:"524"
    },
    {
        device:"tablet",
        count:"134"
    },
]

export const userAgeData = [
    {
        label:"25-34",
        count: 242
    },
    {
        label:"35-44",
        count: 432
    },
    {
        label:"45-54",
        count: 133
    },
    {
        label:"55-64",
        count: 173
    },
    {
        label:"65+",
        count: 96
    },
]
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../components/ui/SettingInput";
import { getCookies } from "../../../../utils/utils";
import SettingDrawer from "../settingDrawer/settingDrawer";

const AccountPasswordFields = () => {

    const [newPassword, setNewPassoword] = useState("")
    const [currentPassword, setCurrentPassoword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false)
    const handleCancel = () => {
        close()
    };

    const handlenewPasswordChangeHandler = (val: string) => {
        setNewPassoword(val)
    }

    const handleConfirmPasswordHandler = (val: string) => {
        setConfirmPassword(val)
    }

    console.log("new passowrd is ", currentPassword)
    console.log("confirmPassword passowrd is ", confirmPassword)


    const handleSubmit = async () => {
        try {
            setLoading(true)
            let token = getCookies('authToken');
            let passwordUpdateResponse = await axios.put('https://api.lusso.dev/api/v1/updatePassword', {
                isNewUser: "false",
                oldPassword: currentPassword,
                newPassword: newPassword

            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (passwordUpdateResponse) {
                setLoading(false)
                toast.success('Password updated Successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {
                        background: '#2E246C',
                        color: 'white',
                        fontWeight: 700,
                        borderRadius: '8px',
                        padding: '16px',
                        marginTop: 50
                    }
                });
                // setUserProfile(userDataResponse.data)
            }
        }
        catch (err) {
            setLoading(false)
        }
    }
    const fields = [
        {
            label: "New Password",
            name: "newPassword",
            type: "password",
            onClick: handlenewPasswordChangeHandler,
            value: newPassword,

            placeholder: "Password",
            className: "border border-gray-600 rounded p-2"
        },
        {
            label: "Confirm Password",
            onClick: handleConfirmPasswordHandler,
            value: confirmPassword,

            name: "confirmPassword",
            type: "password",
            placeholder: "Password",
            className: " border border-gray-600 rounded p-2"
        },
    ];
    const buttons = [
        {
            label: "Save Changes",
            className: "bg-gradient-vertical px-4 py-2 text-white border border-0 rounded",
            onClick: handleSubmit
        },
        {
            label: "Cancel",
            className: "px-8 py-2 rounded border-2 bg-transparent",
            onClick: handleCancel,
            style: {
                border: '2px solid var(--accent1, #7D3CF3)',
                backgroundColor: 'transparent',
            }
        },
    ];
    return (
        <>
            <SettingDrawer
                opened={opened}
                close={close}
                title="Change Password"
                fields={fields}
                buttons={buttons}
            />
            <div className="card-bg-dev rounded-2xl md:px-8 md:py-6 opacity-70 text-white grid grid-cols-1 lg:grid-cols-2 gap-6">
                <span>Set Password</span>
                <div className="lg:col-span-2">
                    <Input
                        label="Current Password"
                        name="password"
                        onChange={(e) => {
                            setCurrentPassoword(e.target.value)
                        }}
                        value={currentPassword}
                        // type="password"
                        placeholder="Password"
                    />
                </div>
                <div className="lg:col-span-2" onClick={() => open()}>
                    <label className="input input-bordered flex justify-between items-center gap-2 mt-6"
                        style={{
                            background:
                                "linear-gradient(125.12deg, #2D246C 6.52%, #1A1442 34.28%, #171232 53.59%, #25204A 78.95%)",
                            border: "1px solid var(--outline, #6C8CFF80)"
                        }}>
                        <input type="text" className="grow" placeholder="Change Password" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70"
                        >
                            <path
                                fillRule="evenodd"
                                d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708-.707L10.293 8l-3.647-3.646a.5.5 0 1 1 .708-.707l4 4z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </label>
                </div>
            </div>
        </>
    );
};

export default AccountPasswordFields;

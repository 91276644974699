export const productTabs = [
  {label: 'App', value: 'overview'},
  {label: 'Game', value: 'game'},
  {label: 'Course', value: 'course'},
  {label: 'Review', value: 'review'},
];

export const productCategory = [
  {label: 'Category 1'},
  {label: 'Category 2'},
  {label: 'Category 3'},
  {label: 'Category 4'},
  {label: 'Category 5'},
];

export const listData = [
  {
    content:
      'By accessing and using LussoMarketplace, you agree to these terms and conditions. If you do not agree with any part of these terms, you may not access the website or use our services.',
  },
  {
    content:
      'We provide links to external websites or platforms where products can be purchased. We do not endorse, guarantee, or assume responsibility for the accuracy or legality of the content on linked websites.',
  },
  {
    content:
      'All content and materials available on LussoMarketplace, including text, graphics, logos, images, and software, are the property of LussoMarketplace or its licensors and are protected by copyright laws.',
  },
  {
    content:
      'LussoMarketplace reserves the right to revise these terms and conditions at any time without prior notice. By continuing to use our platform after any revisions become effective, you agree to be bound by the updated terms.',
  },
  {
    content:
      'If you have any questions about these terms and conditions, please contact us at [contact@lussomarketplace.com].',
  },
  {
    content:
      'LussoMarketplace shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use our platform.',
  },
];

export const appCategories = [
  'Books',
  'Camera',
  'Education',
  'Entertainment',
  'Food & Dining',
  'Hotels',
  'Health & Fitness',
  'Kids',
  'Lifestyle',
  'Media',
  'Music',
  'Navigation',
  'News',
  'Photos',
  'Videos',
  'Weather',
  'Security',
  'Shopping',
  'Social',
  'Sports',
  'Travel',
  'Others',
];

export const gameCategories = [
  'Action',
  'Adventures',
  'Kids',
  'Educational',
  'Classics',
  'Card',
  'Board',
  'Puzzle',
  'Racing',
  'Shooting',
  'Sports',
  'Casino',
  'Multi player',
];

export const aiCategories = [
  'Virtual Assistants and Chatbots',
  'Healthcare',
  'Fiance',
  'Retail and E-commerce AI Solutions',
  'Autonomous Vehicles and Transportation',
  'Manufacturing',
  'Marketing and Sales Tools',
  'Education',
  'Cybersecurity Solutions',
  'Agicultural Technologies',
  ' Entertainment and Media',
  'Natural Language Processing (NLP) Tools',
  'Computer Vision Products',
  'Human Resources Tools',
  'Energy Sector AI Applications',
  'AI Development Platforms and Frameworks',
  'Legal Industry AI Solutions',
  'Real Estate AI Tools',
  'Supply Chain and Logistics AI',
  'Personalization and Recommendation Engines',
  'AI in Human Well-being and Mental Health',
  'Robotic Process Automation (RPA)',
  'AI in Telecommunications',
  'AI in Art and Content Creation',
  'AI-powered Wearables and IoT Devices',
  'Imager Generation',
  'Video Generation',
  'Content generation',
  'Audio and Music Generation',
  'Speech Synthesis and Voice Cloning',
  'Deepfake and Synthetic Media',
  'Code Generation',
  'Automated Writing and Journalism',
  '3D model and Animation Generation',
  'Style Transfer and Image Manipulation',
  'Storytelling and Narrative Generation',
  'Augmented and Virtual Reality',
  'Automated Video Editing',
  'Gaming',
  'Dsign Tools',
  'Voice and Speech Generation',
  'Legal Document Generation',
  'Marketing Content Generation',
  'Photography enchancement',
];

export const movieTabGeners = [
  'Action',
  'Adventure',
  'Animation',
  'Comedy',
  'Drama',
  'Kids & Family',
  'Documentry',
  'Horror',
  'Romance',
  'Romantic Comedy',
  'Sci-Fi/Fantasy',
  'Sports',
  'Thriller',
  'Mystery',
  'Stand-Up',
  'Indeoendent',
  'Reality',
  'Suspense',
  'Crime',
  'Fantasy',
  'International',
  'LGBTQ',
];

export const courseCategories = [
  'Development',
  'Business',
  'Fiannce & Accounting',
  'IT & Software',
  'Office Productivity',
  'Personal Development',
  'Design',
  'Marketing',
  'Lifestyle',
  'Phtography & Video',
  'Health & Fitness',
  'Music',
  'Teaching & Academics',
];

export const serviceCategories = [
  'Strategy Consulting',
  'Management Consulting',
  'Legal Serivices',
  'Health Care',
  'Financial Consulting',
  'Accounting',
  'Operations Consulting',
  'IT & Computer Support',
  'Project Management',
  'Tax Services',
  'Insurance Services',
];

export const languages = [
  'English',
  'Mandarin Chinese',
  'Spanish',
  'Hindi',
  'Arabic',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'Punjabi',
  'German',
  'Javanese',
  'Korean',
  'French',
  'Turkish',
  'Italian',
  'Vietnamese',
  'Urdu',
  'Persian (Farsi)',
  'Swahili',
];

export const infoSectionData = {
  title: 'What you will get',
  data: [
    'Personalized Solutions',
    'Dedicated Legal Advocacy',
    'Comprehensive Representation',
    'Thorough Case Representation',
    'Free Initial Consultation',
  ],
  linkPath: '',
};

export const experienceData = {
  title: 'Experience & qualification',
  data: [
    'Play hundreds of addicting game levels, packed with fun championship puzzles',
    'Spend a great time exploring the empire kingdom bubble shooting legend, and enjoy the classic smooth gameplay',
    'Train your brain, complete puzzle quests, and collect awesome rewards',
    'Reach the epic game level target and win',
    'Relaxing, stress relief time killer game to play whenever and wherever you want',
  ],
  linkPath: '',
};

export const additionalInfoData = {
  publisher: 'Netflix, Inc.',
  genres: 'Entertainment',
  category: 'Apps/Entertainment',
  productSize: '50 GB',
  ageRating: 'PG-18',
  // support: {
  //   phoneNumber: '+18667160414',
  //   email: 'iosappstore@netflix.com',
  // },
  compatibility: {
    devices: ['iPhone', 'iPad', 'iPod touch'],
    requirements: [
      'Requires iOS 12.0 or later.',
      'Requires iPadOS 12.0 or later.',
    ],
  },
};

export const contactInfoData = {
  phone: '+01 123 654 8096',
  email: 'gerolddesign@mail.com',
  address: 'Warne Park Street Pine, FL 33157, New York',
};

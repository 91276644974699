import { createTheme } from "@mantine/core";

export const stepsArr = [
    { label: "Account Type" },
    { label: "Name" },
    { label: "Profile" },
    { label: "Set Password" },
    // { label: "Security" },
];


export const theme = createTheme({
});



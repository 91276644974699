import { useState } from "react";
import cross from "../../assets/images/calender/cross.svg";
import deleteIcon from "../../assets/images/calender/del.png";
import eye from "../../assets/images/calender/eye.svg";
import facebook from "../../assets/images/calender/facebook.svg";
import insta from "../../assets/images/calender/insta.svg";
import linkedin from "../../assets/images/calender/linkdin.png";
import repost from "../../assets/images/calender/repost.svg";
import tiktok from "../../assets/images/calender/tiktok.svg";
import x from "../../assets/images/calender/x.svg";
import "./calenderStyle.scss";

interface AppData {
    date: string;
    time: string;
    desc: string;
    image: any;
}

interface AppDataTableProps {
    data: AppData[];
}

const PostsTable = ({ openCalendar, close: postModalClose, selectedPost, filteredPostsData }: any) => {
    const networkImages: any = {
        facebook,
        instagram: insta,
        linkedin: linkedin,
        tiktok,
        x,
    };
    const [appData, setAppData] = useState<AppData[]>([]);
    const [searchQuery, setSearchQuery] = useState("");


    const filteredData = filteredPostsData?.filter((item: any) =>
        item?.dateTime?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        item?.text?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    const AppDataTable: React.FC<AppDataTableProps> = ({ data }) => {
        return (
            <div className="data-table-wrapper custom-scrollbar">
                <table>
                    <thead className="header-row">
                        <tr
                            style={{
                                background:
                                    "linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)",
                            }}
                        >
                            <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Schedule Date/Time
                            </th>
                            {/* <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Schedule Time
                            </th> */}
                            <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Description
                            </th>
                            <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Platform
                            </th>
                            <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Image
                            </th>
                            <th className="font-semibold text-base text-[#FFFFFF] uppercase">
                                Action
                            </th>
                        </tr>
                    </thead>

                    <tbody className="bg-gradient-to-r from-[#2e246c] to-[#271f57]">
                        {data?.length > 0 ? (
                            data?.map((app: any, index: number) => (
                                <tr key={index} className="prouduct-data-row">
                                    <td className="text-center">{app?.publicationDate?.dateTime}</td>
                                    <td className="text-center text-sm truncate max-w-xs">{app.text}</td>
                                    <td className="flex flex-row items-center text-center justify-center">
                                        <div className="flex flex-row gap-2 items-center justify-center">
                                            {app?.providers?.map((provider: any) => (
                                                <div key={provider.id}>
                                                    {networkImages[provider.network] && (
                                                        <img
                                                            src={networkImages[provider.network]}
                                                            alt={provider.network}
                                                            className="network-icon h-5 w-5"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="items-center justify-center">
                                        {app?.media && app.media?.length > 0 && app.media[0] ? (
                                            <img src={app.media[0]} alt="Image description" className="w-10 h-10" />
                                        ) : (
                                            <p>No image available</p>
                                        )}
                                    </td>

                                    <td className="flex justify-center gap-1 items-center">
                                        <div>
                                            <img src={eye} alt="eye" />
                                        </div>
                                        <div>
                                            <img src={deleteIcon} alt="del" />
                                        </div>
                                        <div>
                                            <img src={repost} alt="repost" />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} className="text-center py-4 text-white">
                                    No posts available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div >
        );
    };

    return (
        <div className="">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-sm md:text-2xl font-bold text-[#FFFFFF]">{selectedPost}</h2>
                <div className="flex items-center gap-1 md:gap-6">
                    <div
                        style={{
                            backgroundImage:
                                "linear-gradient(90deg, #8423F4 0%, rgba(255, 153, 239, 0.86) 100%)",
                            padding: "1px",
                            borderRadius: "9999px",
                        }}
                    >
                        <label
                            className="input input-bordered rounded-full flex items-center gap-2"
                            style={{
                                backgroundImage:
                                    "linear-gradient(125.12deg, #2D246C 6.52%, #1A1442 34.28%, #171232 53.59%, #25204A 78.95%)",
                                backgroundClip: "padding-box",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="h-4 w-4 opacity-70 text-[#C1C1C166]"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <input
                                type="text"
                                className="grow font-medium text-[#C1C1C166] bg-transparent"
                                placeholder="Search Post"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </label>
                    </div>

                    <button className="w-full md:w-auto md:px-4 py-2 text-sm lg:text-lg font-medium text-[#FFFFFF] bg-[#792FFF80] rounded-lg hover:bg-purple-500 transition"
                        onClick={() => {
                            openCalendar();
                            postModalClose()
                        }}
                    >
                        + Create Post
                    </button>

                    <button className="ml-2 text-gray-400 hover:text-white transition w-11" onClick={() => postModalClose()}>
                        <img src={cross} alt="cross" className="" />
                    </button>
                </div>
            </div>
            <div className="flex flex-1 flex-row items-center justify-between"></div>
            <div className="members-data-table">
                <AppDataTable data={filteredData} />
            </div>
        </div>
    );
};

export default PostsTable;

import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiEndpoints } from '../constants/api-endpoints';
import { ROUTES_ENUM } from '../constants/routes.constant';
import makeApiCall from '../lib/apiCall';
import { checkNullOrEmpty, getCookies } from '../utils/utils';

interface SessionContextType {
    isAuthenticated: boolean;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const useSession = (): SessionContextType => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};

interface SessionProviderProps {
    children: ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const checkLoginSession = async () => {
            try {
                const token = await getCookies('authToken');
                const user = await getCookies('authUser');

                if (!location.pathname.startsWith(ROUTES_ENUM.CREATOR_SOCIAL_CALLBACK)) {
                    if (token && user) {
                        if (
                            user?.type === 'developer' &&
                            !location.pathname.startsWith('/dev/')
                        ) {
                            if (!checkNullOrEmpty(user?.analyticsId)) {
                                navigate(ROUTES_ENUM.CREATOR_ROOT);
                            } else {
                                navigate(ROUTES_ENUM.CREATOR_SUBSCRIBE);
                            }
                        }
                    } else if (token) {
                        const loggedinUser = await makeApiCall(apiEndpoints.userProfile);
                        if (
                            loggedinUser?.type === 'developer' &&
                            !location.pathname.startsWith('/dev/')
                        ) {
                            if (!checkNullOrEmpty(loggedinUser?.analyticsId)) {
                                navigate(ROUTES_ENUM.CREATOR_ROOT);
                            } else {
                                navigate(ROUTES_ENUM.CREATOR_SUBSCRIBE);
                            }
                        }
                    }
                }

                setIsAuthenticated(!!token);
            } catch (error) {
                console.error('Error checking login session', error);
                navigate(ROUTES_ENUM.LOGIN);
            }
        };

        checkLoginSession();
    }, []);

    return (
        <SessionContext.Provider value={{ isAuthenticated }}>
            {children}
        </SessionContext.Provider>
    );
};

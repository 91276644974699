import axios from 'axios';
import { toast } from 'react-toastify';
import { envValues } from '../constants/envs';
import { getCookies } from '../utils/utils';

const instance = axios.create({
  baseURL: envValues.apiBaseUrl,
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const token = getCookies('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        toast.error('Your session has expired. Please log in again.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // setTimeout(() => {
        //   window.location.href = '/login';
        // }, 3000);
        return toast.error(error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else if (status === 500) {
        return toast.error('Internal server error.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // return Promise.reject(new Error('Internal Server Error'));
      }
    }
    return toast.error(error, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  },
);

export default instance;
export { axiosInstance };


import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import postIcon from "../../assets/images/calender/createPost.png";
import analyticsIcon from '../../assets/images/products/icons/analytics.png';
import homeIcon from '../../assets/images/products/icons/home.png';
import productIcon from '../../assets/images/products/icons/product_details.png';
import profileIcon from '../../assets/images/products/icons/profile.png';
import settingIcon from '../../assets/images/products/icons/setting.png';
import subscriptionIcon from '../../assets/images/products/icons/subscription.png';
import SideBarLogo from '../../assets/images/re-lusso-logo.png';
import { useScroll } from '../../components/common/ScrollContext';
import { apiEndpoints } from '../../constants/api-endpoints';
import { ICON_ENUM } from '../../constants/icons.constant';
import { ROUTES_ENUM } from '../../constants/routes.constant';
import makeApiCall from '../../lib/apiCall';
import {
  useCurrentOrigin
} from '../../redux/hooks';
import { checkNullOrEmpty, getCookies } from '../../utils/utils';
import './SideBarNew.scss';
import SocialPageModal from './SocialPageModal';

interface CategoryType {
  id: number;
  title: string;
  icon: string;
  activeIcon?: string;
  subCategories?: SubCategoryType[];
}

interface SubCategoryType {
  id: number;
  title: string;
  icon: string;
  addIcon?: boolean;
}

const catRoutes: {
  [key: number]: { parent: string; child?: { [key: number]: string } };
} = {
  1: { parent: '/dev/dashboard' },
  2: { parent: '/dev/allproducts' },
  3: { parent: '/dev/manageprofile' },
  4: { parent: '/dev/purchased' },
  5: { parent: '/dev/purchased' },
  6: { parent: '/dev/members' },
  7: {
    parent: '/dev/analytics',
    child: {
      201: '/dev/analytics/facebook',
      202: '/dev/analytics/instagram',
      203: '/dev/analytics/x',
      204: '/dev/analytics/tiktok',
      205: '/dev/analytics/youtube',
      206: '/dev/analytics/behance',
      207: '/dev/analytics/pinterest',
      208: '/dev/analytics/snapchat',
      209: '/dev/analytics/linkedin',
      210: '/dev/analytics/thread',
    },
  },
  8: { parent: '/dev/settings' },
  9: { parent: '/dev/calender' },

}

const Sidebar = ({ shouldRefresh }: { shouldRefresh: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const baseOrigin = useCurrentOrigin();

  const currentRoute = location.pathname;

  const { scrollToSection } = useScroll();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [addSocialModal, setAddSocialModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [activeSubTab, setActiveSubTab] = useState<number | null>(null);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [socialPages, setSocialPages] = useState([]);
  const [paramsData, setParamsData] = useState<{
    userId: number | string;
    blogId: number | string;
    platform: string;
  }>({ userId: '', blogId: '', platform: '' });

  useEffect(() => {
    if (shouldRefresh) {
      console.log('Sidebar refreshed');
      if (shouldRefresh === 'addproduct') {
        setActiveTab(2);
      } else if (shouldRefresh === 'manageprofile') {
        setActiveTab(3);
      }
    }
  }, [shouldRefresh]);

  useEffect(() => {
    // Replace token and payload mock with actual logic
    const token = 'token';
    if (token) {
      const payload = { role: 'developer' };
      setCategories([
        {
          id: 1,
          title: 'Dashboard',
          icon: homeIcon,
        },
        {
          id: 2,
          title: 'My Product',
          icon: productIcon,
          // subCategories: [
          //   { id: 102, title: 'Apps', icon: appsIcon },
          //   { id: 101, title: 'Games', icon: gameIcon },
          //   { id: 103, title: 'Movies & TV', icon: moviesIcon },
          //   { id: 104, title: 'Content Creator', icon: contentIcon },
          //   { id: 105, title: 'Services', icon: servicesIcon },
          // ],
        },
        {
          id: 9,
          title: 'Create Post',
          icon: postIcon,
        },
        {
          id: 3,
          title: 'Manage Profile',
          icon: profileIcon,
        },
        // {
        //   id: 4,
        //   title: 'Product Details',
        //   icon: product_detailIcon,
        // },
        {
          id: 5,
          title: 'Subscription',
          icon: subscriptionIcon,
        },
        // {
        //   id: 6,
        //   title: 'Teams',
        //   icon: teamsIcon,
        // },
        {
          id: 7,
          title: 'Social Analytics',
          icon: analyticsIcon,
          subCategories: [
            { id: 201, title: 'Facebook', icon: ICON_ENUM?.FACEBOOK?.icon, addIcon: true },
            { id: 202, title: 'Instagram', icon: ICON_ENUM?.INSTAGRAM?.icon, addIcon: true },
            { id: 203, title: 'Twitter', icon: ICON_ENUM?.X?.icon, addIcon: true },
            // { id: 204, title: 'Tiktok', icon: ICON_ENUM?.TIKTOK?.icon },
            // { id: 205, title: 'Youtube', icon: ICON_ENUM?.YOUTUBE?.icon },
            // { id: 206, title: 'Behance', icon: ICON_ENUM?.BEHANCE?.icon },
            // {
            //   id: 207,
            //   title: 'Pinterest',
            //   icon: ICON_ENUM?.PINTEREST?.icon,
            //   addIcon: true,
            // },
            // {
            //   id: 208,
            //   title: 'SnapChat',
            //   icon: ICON_ENUM?.SNAPCHAT?.icon,
            //   addIcon: true,
            // },
            {
              id: 209,
              title: 'LinkedIn',
              icon: ICON_ENUM?.LINKEDIN?.icon,
              addIcon: true,
            },
            // {
            //   id: 210,
            //   title: 'Thread',
            //   icon: ICON_ENUM?.THREAD?.icon,
            //   addIcon: true,
            // },
          ],
        },
        {
          id: 8,
          title: 'Settings',
          icon: settingIcon,
        },
      ]);
      checkActiveRoute();
    }
  }, []);

  const checkActiveRoute = (): void => {
    Object.entries(catRoutes).forEach(([key, value]) => {
      const parentId = Number(key);

      // Check if the current route matches the parent route
      if (value.parent === currentRoute) {
        setActiveTab(parentId);
      }

      // Check if the current route matches any child routes
      if (value.child) {
        Object.entries(value.child).forEach(([childKey, childValue]) => {
          if (childValue === currentRoute) {
            setActiveTab(parentId);
            setActiveSubTab(Number(childKey));
          }
        });
      }
    });
  };

  const handleSubCategoryAction = (id: number, parent: number = 0) => {
    if (id === 101) {
      scrollToSection('games');
      setActiveSubTab(id);
    } else if (id === 102) {
      scrollToSection('apps');
      setActiveSubTab(id);
    } else if (id === 103) {
      scrollToSection('movies');
      setActiveSubTab(id);
    } else if (id === 104) {
      scrollToSection('courses');
      setActiveSubTab(id);
    } else if (id === 105) {
      scrollToSection('services');
      setActiveSubTab(id);
    } else {
      const redirectRoute = catRoutes?.[parent]?.child?.[id] ?? '';
      if (!checkNullOrEmpty(redirectRoute)) {
        setActiveTab(parent);
        setActiveSubTab(id);
        navigate(redirectRoute);
      }
    }
  };

  const ToggleTab = (id: number) => {
    if (!checkNullOrEmpty(catRoutes[id]?.parent)) {
      setActiveTab(id);
      setActiveSubTab(null);
      navigate(catRoutes[id]?.parent);
    }
  };

  const addSocialPlatform = async (platform: string) => {
    const creator = getCookies('authUser');
    const platformName = platform?.toLowerCase()
    if (creator) {
      let newContent = { ...apiEndpoints.socialSignin };
      newContent.params.query.platformName = platformName;

      const signinLink = await makeApiCall(newContent);

      if (!signinLink) return
      const newWindow = window.open(
        signinLink + '&returnPage=' + baseOrigin + ROUTES_ENUM.CREATOR_SOCIAL_CALLBACK,
        'myWindow',
        'width=1000,height=700',
      );

      const handleMessage = (event: MessageEvent) => {
        if (event.origin === baseOrigin) {
          const { userId, blogId } = event.data;
          console.log('User ID:', userId);
          console.log('Blog ID:', blogId);
          if (!checkNullOrEmpty(blogId)) {
            setParamsData({ userId, blogId, platform: platformName });

            const targetPlatform = apiEndpoints?.[platformName as keyof typeof apiEndpoints] ?? {};
            if (targetPlatform?.addPage) {
              if (!checkNullOrEmpty(targetPlatform?.getPages)) {
                makeApiCall(targetPlatform.getPages)
                  .then(resp => {
                    setSocialPages(resp ?? []);
                    if (!checkNullOrEmpty(resp)) setAddSocialModal(true);
                  })
                  .catch(error => console.error(error));
              }
            }
          } else navigateOnAnalyticPlatform(platformName)

          // newWindow?.close();
        }
      };

      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  };

  const socialPageHandle = async (id: string) => {
    setInProgress(true);
    const key: keyof typeof paramsData = 'blogId';
    const addPageContent = { ...apiEndpoints[paramsData.platform as keyof typeof apiEndpoints].addPages }
    const addLikPage = {
      ...addPageContent,
      endpoint:
        addPageContent.endpoint +
        paramsData[key] +
        '?linkedInPageId=' +
        id,
    };
    await makeApiCall(addLikPage);
    setInProgress(false);
    setAddSocialModal(false);
    navigateOnAnalyticPlatform(paramsData.platform)
    // navigate(location.pathname + '/' + paramsData.platform)
  };

  const navigateOnAnalyticPlatform = (platform: string) => {
    if (checkNullOrEmpty(platform)) return;
    let platformUpper = platform.toUpperCase()
    const socialMediaLink = ROUTES_ENUM[platformUpper as keyof typeof ROUTES_ENUM];
    if (checkNullOrEmpty(socialMediaLink)) return;
    navigate(socialMediaLink)
  }

  return (
    <div
      className="sidenav-bg-shadow side-nav-container float-left flex flex-col gap-y-9"
      style={{ maxWidth: 'fit-content', overflow: 'auto' }}
    >
      <SocialPageModal
        platform={paramsData.platform}
        open={addSocialModal}
        loading={inProgress}
        options={socialPages}
        onClose={() => setAddSocialModal(false)}
        onConfirm={socialPageHandle}
      />
      <div className="px-5 py-5">
        <div className="mb-5 px-3">
          <img className="w-52 cursor-pointer" src={SideBarLogo} alt="lusso-logo" onClick={() => ToggleTab(1)} />
        </div>
        <div className="flex flex-col gap-2">
          {categories.map(category => (
            <div key={category.id}>
              <div
                onClick={() => ToggleTab(category.id)}
                className={`flex flex-row gap-x-2 justify-start items-center ${activeTab === category.id
                  ? 'category-card-active-sharp'
                  : 'category-card'
                  }`}
              >
                <div>
                  <img className="w-5" src={category.icon} alt="" />
                </div>
                <div>
                  <p
                    className="text-[15px] font-medium text-white"
                    style={{
                      color:
                        activeTab === category.id ? 'var(--4, #00F0FB)' : '',
                    }}
                  >
                    {category.title}
                  </p>
                </div>
              </div>
              {activeTab === category.id && category.subCategories && (
                <div style={{ paddingLeft: 16 }}>
                  {category.subCategories.map(subCategory => (
                    <div
                      key={subCategory.id}
                      onClick={() => {
                        // handleSubCategoryAction(subCategory.id, category?.id);
                        addSocialPlatform(subCategory.title)
                      }}
                      className={`flex flex-row gap-x-2 justify-between items-center ${activeSubTab === subCategory.id
                        ? 'category-card-active-sharp'
                        : 'category-card'
                        }`}
                    >
                      <div className="flex flex-row gap-x-2 justify-start items-center">
                        <div>
                          <img className="w-5" src={subCategory.icon} alt="" />
                        </div>
                        <div>
                          <p
                            className="text-[15px] font-medium text-white"
                            style={{
                              color:
                                activeSubTab === subCategory.id
                                  ? 'var(--4, #00F0FB)'
                                  : '',
                            }}
                          >
                            {subCategory.title}
                          </p>
                        </div>
                      </div>
                      {subCategory.addIcon && (
                        // <a href='https://app.metricool.com/socialConnection?blogId=4065887&userId=2241311&userToken=TOLZVGNZXBNYVWVSPOHWDRFOHTEZBQBVLRJMLAMETVYHCNIFYNFQYSRDKQHWHDCP&from=linkedin' target="_parent">
                        // <img className="w-5" src={ICON_ENUM?.PLUS_WITH_CIRCLE?.icon ?? ''} alt="" />
                        <img
                          className="w-5"
                          src={ICON_ENUM?.PLUS_WITH_CIRCLE?.icon ?? ''}
                          alt=""
                        // onClick={() => addSocialPlatform(subCategory.title)}
                        />
                        // </a>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

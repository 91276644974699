import axios from 'axios';
import { allCountries } from 'country-region-data';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import IconEye from '../../assets/icons/eye';
import IconInfoCircle from '../../assets/icons/info';
import Logo from '../../assets/images/LussoAiLogo.svg';
import MobileLogo from '../../assets/images/LussoLogovert.svg';
import BecomeCreatorButton from '../../components/common/BecomeCreatorBtn';
import CustomSelect from '../../components/common/select';
import { setCookies } from '../../utils/utils';
import './auth.scss';
const AccountSignup = () => {
  const navigate = useNavigate();
  const [userName, setUserName]: any = useState('');
  const [password, setPassword]: any = useState('');
  const [confirmpassword, setConfirmPassword]: any = useState('');
  const [email, setEmail]: any = useState('');
  const [age, setAge]: any = useState('');
  const [state, setState]: any = useState('');
  const [country, setCountry]: any = useState('');
  const [isChecked, setIsChecked]: any = useState(false);
  const [invalidField, setInvalidField]: any = useState('');
  const [invalidError, setInvalidError]: any = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [regions, setRegions] = useState<OptionType[]>([]);
  const [errorMsg, setErrorMsg] = useState<any>();

  type OptionType = {
    value: string;
    label: string;
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleCountryChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setCountry(selectedOption);
    setState(null); // Clear region selection when country changes

    const countryData = allCountries.find(
      item => item[0] === selectedOption.label,
    );
    if (countryData && countryData[2].length > 0) {
      const regionOptions = countryData[2].map(region => ({
        value: region[1],
        label: region[0],
      }));
      setRegions(regionOptions);
    } else {
      setRegions([]);
    }
  };

  const handleRegionChange = (selectedOption: any) => {
    setState(selectedOption);
  };

  const countryOptions = allCountries.map(country => ({
    value: country[1],
    label: country[0],
  }));

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(invalidError, invalidField);
    setIsLoading(true);
    setErrorMsg('');
    if (!validateFields()) {
      setIsLoading(false);
      return;
    }
    let apiRequest = {
      username: userName,
      email: email,
      password: password,
      ageGroup: age,
      state: state.label,
      country: country.label,
    };
    axios
      .post('https://api.lusso.dev/api/v1/register', apiRequest)
      .then(response => {
        console.log('response', response);
        setIsLoading(false);
        let authToken = response.data?.token;
        setCookies('authToken', authToken);
        Cookies.set('email', email, { expires: 1 });
        if (response.data.isNewUser) {
          navigate('/verify');
        } else {
          navigate('/dev/dashboard');
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
        setErrorMsg(error?.response?.data?.message);
      });
  };

  const validateFields = () => {
    if (
      !/^(?=[a-zA-Z0-9_-]{1,15}$)[a-zA-Z][a-zA-Z0-9_-]{2,15}$/.test(userName)
    ) {
      setInvalidField('username');
      setInvalidError('Username should be alphanumeric');
      return false;
    }

    if (
      !/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(
        email,
      )
    ) {
      setInvalidField('email');
      setInvalidError('Invalid email provided');
      return false;
    }

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password,
      )
    ) {
      setInvalidField('password');
      setInvalidError('Please enter a valid password');
      return false;
    }

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        confirmpassword,
      )
    ) {
      setInvalidField('confirmpassword');
      setInvalidError('Please re-enter your password');
      return false;
    }

    if (password !== confirmpassword) {
      setInvalidField('matchpassword');
      setInvalidError('Password and confirm password should match each other');
      return false;
    }

    if (!/^(18-24|24-60|60\+)$/.test(age)) {
      setInvalidField('age');
      setInvalidError('Invalid age');
      return false;
    }

    if (!country) {
      setInvalidField('country');
      setInvalidError('Invalid country provided');
      return false;
    }

    if (!state) {
      setInvalidField('state');
      setInvalidError('Invalid state provided');
      return false;
    }

    if (!isChecked) {
      return false;
    }

    return true;
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 50,
      border: `1px solid ${state.selectProps.invalidField === 'credserror'
        ? '#F04438'
        : 'rgb(137 108 255 / 80%)'
        }`, // Conditional border color
      backgroundColor: 'rgba(46, 36, 108, 0.1)', // Updated background color
      color: '#FFFFFF99',
      minHeight: 48,
      paddingLeft: 8, // Fixed typo from padddingLeft to paddingLeft
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#FFFFFF99',
      paddingLeft: 8, // Fixed typo from padddingLeft to paddingLeft
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#FFFFFF99',
      paddingLeft: 8, // Fixed typo from padddingLeft to paddingLeft
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'rgba(4, 4, 4)',
      borderRadius: 10,
      padding: 0, // Remove any extra padding if needed
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0, // Remove any extra padding if needed
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isFocused
        ? 'rgba(167, 104, 253, 0.8)'
        : 'rgba(4, 4, 4)',
      color: '#FFFFFF99',
      padding: '8px 12px', // Adjust padding if needed
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the indicator separator
    }),
  };

  return (
    <div className="w-full">
      <div className="w-full m-auto clearfix font-Jakarta-sans h-100 vh-100 flex items-center justify-center login-container login-banner">
        <div
          className="noMobile flex flex-1 items-end justify-end"
          style={{ height: '100vh' }}
        >
          {/* <img src={LoginBannerSlide} alt="Banner" /> */}
          <div
            className="flex flex-1 justify-center uppercase font-bold"
            style={{
              letterSpacing: 12,
              fontSize: '2.5rem',
              paddingBottom: '30px',
              paddingRight: '50px',
              position: 'relative',
            }}
          >
            <span className="text-white" style={{ fontSize: '2rem' }}>
              {' '}
              {/* Increased font size */}
              <span style={{ fontSize: '2rem' }}>EXPLORING</span> <br />
              <span style={{ fontSize: '2rem' }}>THE WORLD OF</span> <br />
              <span
                style={{ fontSize: '3rem' }}
                className="text-[#0FF] font-bold"
              >
                LUSSO
              </span>{' '}
              <br />
              <span style={{ fontSize: '3rem' }}>UNIVERSE</span>
            </span>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-between">
          <div className="login-container-right">
            <div className="auth-top-container w-full flex flex-row justify-between items-center">
              <Link to={'/'} style={{ textDecoration: 'none' }}>
                <div className="/static/media/logo.a9d97f8a67185525ef2619183873d79a.svg">
                  <img
                    src={isMobile ? MobileLogo : Logo}
                    alt="Logo"
                    style={{ width: '100%', height: 'auto' }} // Adjust size as needed
                  />
                </div>
              </Link>

              <div className="auth-become-creator">
                {/* <button
                  onClick={() => {
                    navigate('/auth/devonboard');
                  }}
                  className="creator-btn"
                  style={{
                    borderRadius: 50,
                    border: '1px solid #792FFF',
                    textTransform: 'none',
                  }}
                >
                  Become A Creator &nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8 5l7 7-7 7"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button> */}
                <BecomeCreatorButton />
              </div>
            </div>
            <div className="auth-create-acc-title w-full flex flex-col justify-center items-center mt-7">
              <h2 className="text-[#00FFFF] text-[20px] font-bold  text-center">
                Create Account
              </h2>
              <p className="text-white/90 text-base font-normal text-center mb-8">
                Sign Up today to join a Niche, Tech-Savy community of creators
                from all around the globe!
              </p>
            </div>

            {/* <div className="w-full flex flex-col items-center gap-6">
              <button
                type="submit"
                className="ac-google-login-btn gap-2"
                style={{
                  borderRadius: 50,
                  border: '1px solid #A768FD',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13.5" cy="13.5" r="13" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.7172 5.14446C16.7172 5.93682 16.3672 6.72917 15.8733 7.30089C15.3483 7.93281 14.4427 8.40421 13.7223 8.4043C13.64 8.4043 13.5576 8.3942 13.5062 8.3842C13.4959 8.34409 13.4753 8.22367 13.4753 8.10335C13.4753 7.30089 13.8973 6.50853 14.3501 6.00703C14.9264 5.3551 15.8835 4.8636 16.6863 4.8335C16.7069 4.92381 16.7172 5.03413 16.7172 5.14446ZM19.5484 10.1357C19.5683 10.1229 19.5821 10.1141 19.5885 10.1092C18.5182 8.61485 16.892 8.57464 16.4391 8.57455C15.7472 8.57455 15.1278 8.81382 14.6071 9.01498C14.2295 9.16086 13.9038 9.28669 13.6399 9.28669C13.3488 9.28669 13.0154 9.1554 12.6434 9.00888C12.1738 8.8239 11.6425 8.61465 11.0567 8.61465C9.08085 8.61465 7.07397 10.2095 7.07397 13.2185C7.07397 15.094 7.81495 17.0699 8.7309 18.3438C9.52328 19.427 10.2128 20.3097 11.2008 20.3097C11.6694 20.3097 12.0129 20.1676 12.3742 20.0181C12.7747 19.8524 13.197 19.6778 13.8355 19.6778C14.4802 19.6778 14.8656 19.8418 15.2374 19.9999C15.5837 20.1473 15.9181 20.2896 16.4392 20.2896C17.5198 20.2896 18.23 19.3368 18.9092 18.3839C19.6708 17.3007 19.9898 16.2375 20.0001 16.1874C19.9383 16.1674 17.8698 15.3549 17.8698 13.058C17.8698 11.2093 19.2789 10.308 19.5484 10.1357Z"
                    fill="black"
                  />
                </svg>
                Sign in with Apple
              </button>
              <button
                type="submit"
                className="ac-google-login-btn gap-2"
                style={{
                  borderRadius: 50,
                  border: '1px solid #A768FD',
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_75_662)">
                    <path
                      d="M23.766 12.2765C23.766 11.4608 23.6999 10.6406 23.5588 9.83813H12.24V14.4591H18.7217C18.4528 15.9495 17.5885 17.2679 16.323 18.1056V21.104H20.19C22.4608 19.014 23.766 15.9274 23.766 12.2765Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.50253 14.3002C4.99987 12.8099 4.99987 11.196 5.50253 9.70569V6.61475H1.51649C-0.18551 10.0055 -0.18551 14.0004 1.51649 17.3912L5.50253 14.3002Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_75_662">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Sign in with Google
              </button>
            </div> */}
            {/* <div className="w-full flex flex-1 items-center justify-between">
              <div className="horizontal-divider mb-8 mt-8"></div>
              <div>
                <span
                  style={{ color: '#E1E1E1' }}
                  className="text-[#E1E1E1] text-[14px] mb-8 mt-8 text-center"
                >
                  OR
                </span>
              </div>
              <div className="horizontal-divider mb-8 mt-8"></div>
            </div> */}
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              className="auth-form-container"
            >
              <div className="auth-input-container w-full flex flex-row justify-between items-start">
                <div className="flex flex-col gap-y-6">
                  <div>
                    <label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Enter your name"
                        className="ac-frm-input rounded-pill badge h-[50px]"
                        value={userName || ''}
                        style={{
                          borderRadius: 50,
                          border: `1px solid ${invalidField === 'credserror'
                            ? '#F04438'
                            : 'rgb(137 108 255 / 80%)'
                            }`,
                          background: 'rgba(46, 36, 108, 0.1)',
                          color: '#fff',
                        }}
                        onChange={e => {
                          setInvalidField('');
                          setInvalidError('');
                          setUserName(e.target.value);
                        }}
                      />
                    </label>
                    {invalidField === 'username' && (
                      <span className="errorField">{invalidError}</span>
                    )}
                  </div>
                  <div>
                    <label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Enter your email"
                        className="ac-frm-input rounded-pill badge h-[50px]"
                        value={email || ''}
                        style={{
                          borderRadius: 50,
                          border: `1px solid ${invalidField === 'email'
                            ? '#F04438'
                            : 'rgb(137 108 255 / 80%)'
                            }`,
                          background: 'rgba(46, 36, 108, 0.1)',
                          color: '#fff',
                        }}
                        onChange={e => {
                          setInvalidField('');
                          setInvalidError('');
                          setEmail(e.target.value);
                        }}
                      />
                    </label>
                    {invalidField === 'email' && (
                      <span className="errorField">{invalidError}</span>
                    )}
                  </div>
                  <div className="relative">
                    <label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder="Enter your password"
                        className="ac-frm-input h-[50px] pl-12 pr-12"
                        value={password || ''}
                        style={{
                          borderRadius: 50,
                          border: `1px solid ${invalidField === 'password'
                            ? '#F04438'
                            : 'rgb(137 108 255 / 80%)'
                            }`,
                          background: 'rgba(46, 36, 108, 0.1)',
                          color: '#fff',
                        }}
                        onChange={e => {
                          setInvalidField('');
                          setInvalidError('');
                          setPassword(e.target.value);
                        }}
                      />
                    </label>
                    <span
                      onClick={() => setShowTooltip(!showTooltip)}
                      className="absolute right-12 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      style={{ zIndex: 10 }}
                    >
                      <IconInfoCircle color="#888" />
                    </span>
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      style={{ zIndex: 10 }}
                    >
                      <IconEye color={showPassword ? '#A768FD' : '#888'} />
                    </span>
                    {showTooltip && (
                      <div className="absolute z-50 top-full mt-2 w-full p-3 bg-white border border-gray-300 shadow-lg rounded-lg text-sm text-gray-700">
                        <ul className="list-disc pl-4">
                          <li className="text-light-blue">
                            At least one number or special character
                          </li>
                          <li
                            className={
                              password.match(/[A-Z]/)
                                ? 'text-light-blue'
                                : 'text-red-500'
                            }
                          >
                            At least one upper case letter
                          </li>
                          <li
                            className={
                              password.match(/[a-z]/)
                                ? 'text-light-blue'
                                : 'text-red-500'
                            }
                          >
                            At least one lower case letter
                          </li>
                          <li
                            className={
                              password.length >= 8
                                ? 'text-light-blue'
                                : 'text-red-500'
                            }
                          >
                            At least 8 characters long
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="absolute bottom-[-20px] w-full">
                      {invalidField === 'password' && (
                        <span className="text-red-500 text-xs">
                          {invalidError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="relative">
                    <label>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder="Re-enter your password"
                        className="ac-frm-input h-[50px] pl-12 pr-12"
                        value={confirmpassword || ''}
                        style={{
                          borderRadius: 50,
                          border: `1px solid ${invalidField === 'confirmpassword' ||
                            invalidField === 'matchpassword'
                            ? '#F04438'
                            : 'rgb(137 108 255 / 80%)'
                            }`,
                          background: 'rgba(46, 36, 108, 0.1)',
                          color: '#fff',
                        }}
                        onChange={e => {
                          setInvalidField('');
                          setInvalidError('');
                          setConfirmPassword(e.target.value);
                        }}
                      />
                    </label>
                    <span
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      style={{ zIndex: 10 }}
                    >
                      <IconEye
                        color={showConfirmPassword ? '#A768FD' : '#888'}
                      />
                    </span>
                    <div className="absolute bottom-[-20px] w-full">
                      {(invalidField === 'confirmpassword' ||
                        invalidField === 'matchpassword') && (
                          <span className="errorField">{invalidError}</span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-6">
                  <div>
                    <label>
                      <div
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          width: '100%' /* Adjust width as needed */,
                        }}
                      >
                        <CustomSelect
                          value={age ? { value: age, label: age } : null} // Pass null when no value is selected
                          onChange={(newValue, actionMeta) => {
                            console.log('New Value:', newValue);

                            if (
                              newValue &&
                              typeof newValue === 'object' &&
                              'value' in newValue
                            ) {
                              setInvalidField('');
                              setAge(newValue.value); // Extract the value property
                              console.log('Updated Age:', newValue.value);
                            } else {
                              setAge(null); // or some default value
                            }
                          }}
                          invalidField={invalidField}
                        />

                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF99"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M6 9l6 6 6-6" />
                          </svg>
                        </div>
                      </div>
                    </label>

                    {invalidField === 'age' && (
                      <span className="errorField">{invalidError}</span>
                    )}
                  </div>
                  {/* <div>
        <label htmlFor="country">Country:</label>
        <Select
          id="country"
          options={countryOptions}
          value={country}
          onChange={handleCountryChange}
        />
      </div>
      {regions.length > 0 && (
        <div>
          <label htmlFor="region">State/Region:</label>
          <Select
            id="region"
            options={regions}
            value={state}
            onChange={handleRegionChange}
          />
        </div>
      )} */}
                  <div className="relative">
                    <Select
                      placeholder="Select Country"
                      value={country}
                      styles={customStyles}
                      options={countryOptions}
                      onChange={handleCountryChange}
                    />
                    {invalidField === 'country' && (
                      <span className="errorField">{invalidError}</span>
                    )}
                  </div>

                  {regions.length > 0 && (
                    <div>
                      <label>
                        <Select
                          // className="ac-frm-select-input rounded-pill badge h-[50px]"
                          placeholder="Select State"
                          value={state}
                          styles={customStyles}
                          options={regions}
                          onChange={handleRegionChange}
                        />
                      </label>
                      {invalidField === 'state' && (
                        <span className="errorField">{invalidError}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center mt-6">
                {errorMsg ? (
                  <span
                    style={{ color: 'red', fontSize: '20px', fontWeight: 700 }}
                  >
                    {errorMsg}
                  </span>
                ) : (
                  ''
                )}
                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer p-4">
                  <input
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="hidden"
                  />
                  <span
                    className="w-5 h-5 border border-[#464070] rounded-sm bg-[#353057]"
                    style={{ borderRadius: 4 }}
                  ></span>
                  <p className="text-sm text-[#C1C1C1] select-none">
                    I have read and accept{' '}
                    <span className="text-[#5E91FF]">Terms and conditions</span>{' '}
                    and
                    <span className="hidden md:inline">
                      <br />
                    </span>{' '}
                    {/* Hide on mobile, show on web */}
                    <span className="text-[#5E91FF]">privacy policy</span>
                  </p>
                </label>
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  type="submit"
                  className="ac-signup-btn"
                  style={{
                    borderRadius: 50,
                    border: 'none',
                    background: !isLoading
                      ? 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)'
                      : '',
                    textTransform: 'capitalize',
                    paddingLeft: 50,
                    paddingRight: 50,
                    width: window.innerWidth >= 768 ? '500px' : '300px', // Adjust width based on screen size
                    position: 'relative', // Add position relative to button
                  }}
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                      }}
                    >
                      {/* Add your loader component here */}
                      <div className="loader"></div>
                    </div>
                  )}
                  {!isLoading ? 'Create Account' : ''}
                </button>
              </div>
            </form>
            <div
              className="text-center text-base font-normal mt-7"
              style={{
                marginTop: window.innerWidth < 768 ? '0.02rem' : '1.75rem',
              }}
            >
              <span className="text-white/50">Already have an account?</span>{' '}
              <Link
                to="/login"
                className="text-[#5E91FF] hover:underline font-bold"
              >
                Log In
              </Link>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountSignup;

import { useNavigate } from "react-router-dom";

const BecomeCreatorButton = () => {
    const navigate = useNavigate();

    return (
        <button
            className="relative mt-10 lg:mt-0 flex items-center justify-center px-2 py-2 overflow-hidden font-bold text-white group hover:border-2 hover:border-gradient-to-r from-[#F873B4] to-[#4300BD]"
            onClick={() => {
                navigate('/devonboard');
            }}
            style={{
                width: 240,
                borderRadius: 50,
                border: '1px solid transparent',
                backgroundImage: `
                    linear-gradient(to right, #F873B4, #4300BD),
                    linear-gradient(to left, #F873B4, #4300BD)
                    `,
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
                backgroundPosition: 'left,right',
                backgroundSize: '100% 100%, 100% 100%',
                backgroundRepeat: 'no-repeat',
                padding: '1.5px', // Adjust the padding as needed
            }}
        >
            <span className="relative bg-[#17153A] rounded-full py-3 w-full pe-4">
                Become A Creator
            </span>
            <svg className="absolute right-6 mt-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
        </button>

    );
};

export default BecomeCreatorButton;

import { useState } from 'react';
import img_placeholder from '../../../assets/images/placeholder-img.svg';
import DataGrid from '../../../components/dataGrid/DataGrid';
import { ICON_ENUM } from '../../../constants/icons.constant';
import { daysAgoDate } from '../../../utils/utils';
import AnalyticCompTop from './Components/AnalyticsCompTop';
import BarTilesAndGraph from './Components/BarTilesAndGraph';
import './SocialAnalytics.scss';

function isIconEnumKey(key: string): key is keyof typeof ICON_ENUM {
    return key in ICON_ENUM;
}

const tilesStats = [
    {
        title: 'Facebook',
        headerIcon: ICON_ENUM?.FACEBOOK?.icon,
        borderColor: ICON_ENUM?.FACEBOOK?.borderColor,
        count: '1852',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Youtube',
        headerIcon: ICON_ENUM?.YOUTUBE?.icon,
        borderColor: ICON_ENUM?.YOUTUBE?.borderColor,
        count: '198',
        bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Instagram',
        headerIcon: ICON_ENUM?.INSTAGRAM?.icon,
        borderColor: ICON_ENUM?.INSTAGRAM?.borderColor,
        count: '214',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'X',
        headerIcon: ICON_ENUM?.X?.icon,
        borderColor: ICON_ENUM?.X?.borderColor,
        count: '167',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'LinkedIn',
        headerIcon: ICON_ENUM?.LINKEDIN?.icon,
        borderColor: ICON_ENUM?.LINKEDIN?.borderColor,
        count: '1852',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Tiktok',
        headerIcon: ICON_ENUM?.TIKTOK?.icon,
        borderColor: ICON_ENUM?.TIKTOK?.borderColor,
        count: '142',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Twitch',
        headerIcon: ICON_ENUM?.TWITCH?.icon,
        borderColor: ICON_ENUM?.TWITCH?.borderColor,
        count: '715',
        bodyIcon: ICON_ENUM?.UP_TREND?.icon,
        text: '+8.2k increase/day',
    },
    {
        title: 'Behance',
        headerIcon: ICON_ENUM?.BEHANCE?.icon,
        borderColor: ICON_ENUM?.BEHANCE?.borderColor,
        count: '98',
        bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
        text: '+8.2k increase/day',
    },
];

const lineChartDataSet = {
    configuration: {
        gradientStroke: {
            likes: [
                { offset: '8.66%', stopColor: '#1C36B7' },
                { offset: '90.78%', stopColor: '#1C98D3' },
            ],
            followers: [
                { offset: '14.6%', stopColor: '#FF0000' },
                { offset: '85.41%', stopColor: '#9B0000' },
            ],
            impressions: [
                { offset: '13.8%', stopColor: '#5342D6' },
                { offset: '18.4%', stopColor: '#7739C6' },
                { offset: '25.1%', stopColor: '#A52DB0' },
                { offset: '28.46%', stopColor: '#B729A8' },
                { offset: '37.01%', stopColor: '#CE257E' },
                { offset: '47.52%', stopColor: '#E82250' },
                { offset: '52.8%', stopColor: '#F2203E' },
                { offset: '65.79%', stopColor: '#F2203E' },
                { offset: '67.35%', stopColor: '#F32D40' },
                { offset: '75.25%', stopColor: '#F86C48' },
                { offset: '81.93%', stopColor: '#FB994E' },
                { offset: '87.06%', stopColor: '#FDB652' },
                { offset: '90.03%', stopColor: '#FEC053' },
            ],
        },
        lines: [
            {
                dataKey: 'likes',
                stroke: 'url(#likes)',
            },
            {
                dataKey: 'followers',
                stroke: 'url(#followers)',
            },
            {
                dataKey: 'impressions',
                stroke: 'url(#impressions)',
            },
            {
                dataKey: 'pageVisits',
            },
        ],
    },
    dataSet: [
        {
            date: 'Aug 8',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 18',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 28',
            likes: 198,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 8',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 18',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 28',
            likes: 205,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
    ],
};

const dataSet = [
    {
        name: 'Transform your digital dreams into...',
        network: 'FACEBOOK',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'INSTAGRAM',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'LINKEDIN',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'TIKTOK',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'X',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'YOUTUBE',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
    {
        name: 'Transform your digital dreams into...',
        network: 'THREAD',
        date: 'Aug-25-2024',
        timestamp: '07:01 AM',
        impressions: '20.37K',
        interaction: '130',
    },
];

const SocialAnalytics = () => {
    const [dates, onChange] = useState<{ [key: string]: null | Date }>({
        startDate: daysAgoDate(30),
        endDate: new Date(),
    });

    // const { loading, socialAnalytics } = useAnalyticsStatsHook(apiEndpoints.getSummaryStatistics, dates, [dates])
    const columns = [
        {
            header: '',
            target: 'name',
            cellRender: (value: any, rowId: number) => (
                <div className="flex flex-col lg:flex-row gap-5 justify-start">
                    <img
                        src={img_placeholder ?? ''}
                        alt={'img_placeholder_' + rowId}
                        width={35}
                    />
                    <div className="space-y-3">
                        <p>{value ?? ''}</p>
                        <div className="flex flex-col lg:flex-row justify-between">
                            <button className="flex items-center gap-2">
                                <img
                                    src={ICON_ENUM?.FILE_MORE_LINE?.icon ?? ''}
                                    alt={'FILE_MORE_LINE_' + rowId}
                                />{' '}
                                <p>More</p>
                            </button>
                            <button className="flex items-center gap-2">
                                <img
                                    src={ICON_ENUM?.BX_BOOK?.icon ?? ''}
                                    alt={'BX_BOOK_' + rowId}
                                />{' '}
                                <p>Reuse content</p>
                            </button>
                            <button className="flex items-center gap-2">
                                <img
                                    src={ICON_ENUM?.VIEW_CIRCLE?.icon ?? ''}
                                    alt={'VIEW_CIRCLE_' + rowId}
                                />{' '}
                                <p>View</p>
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            header: 'Network',
            target: 'network',
            cellRender: (value: string, rowNo: number) => (
                <div className="flex justify-center">
                    <img
                        src={isIconEnumKey(value) ? ICON_ENUM[value].icon : ''}
                        width={25}
                        alt={value ?? 'network_Img_' + rowNo}
                    />
                </div>
            ),
        },
        {
            header: 'Date',
            target: 'date',
            cellRender: (value: string, rowNo: number, rowData: any) => (
                <div className="flex flex-col">
                    <span>{value ?? ''}</span> <span>{rowData?.timestamp ?? ''}</span>
                </div>
            ),
        },
        {
            header: 'Impressions',
            target: 'impressions',
        },
        {
            header: 'Interaction',
            target: 'interaction',
        },
    ];
    const compaignColumns = [
        {
            header: '',
            target: 'name',
            cellRender: (value: any, rowId: number) => (
                <div className="flex flex-col space-y-1 items-start">
                    <p>{value ?? ''}</p>
                    <p>Link clicks</p>
                    <button className="flex items-center gap-2">
                        <img src={ICON_ENUM?.STATS?.icon ?? ''} alt={'STATS_' + rowId} />{' '}
                        <p>More stats</p>
                    </button>
                </div>
            ),
        },
        {
            header: 'Network',
            target: 'network',
            cellRender: (value: string, rowNo: number) => (
                <div className="flex justify-center">
                    <img
                        src={ICON_ENUM?.META_LOGO?.icon ?? ''}
                        width={25}
                        alt={value ?? 'network_Img_' + rowNo}
                    />
                </div>
            ),
        },
        {
            header: 'Updated',
            target: 'date',
            cellRender: (value: string, rowNo: number, rowData: any) => (
                <div className="flex flex-col">
                    <span>{value ?? ''}</span> <span>{rowData?.timestamp ?? ''}</span>
                </div>
            ),
        },
        {
            header: 'Impressions',
            target: 'impressions',
        },
    ];

    return (
        <div className="dashboard-container text-white p-6">
            {/* Header Section */}
            <AnalyticCompTop title='Dashboard App Analytics' onChangeCalender={onChange} calenderValue={dates} />

            <div className="space-y-5">
                <h2 className="text-2xl">Account</h2>
                <BarTilesAndGraph title='Followers' data={{ tiles: tilesStats, lineChartDataSet }} />
                <BarTilesAndGraph title='Impressions' data={{ tiles: tilesStats, lineChartDataSet }} />

                <h2 className="text-2xl">Posts</h2>
                <BarTilesAndGraph title='Interactions' data={{ tiles: tilesStats, lineChartDataSet }} />
                <BarTilesAndGraph title='Number of posts' data={{ tiles: tilesStats, lineChartDataSet }} />
                <div className="p-4 card-bg-dev rounded-2xl">
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-lg font-bold">List of posts</span>
                        <button>
                            <img src={ICON_ENUM?.THREE_DOTS?.icon ?? ''} alt="more options" />
                        </button>
                    </div>
                    <DataGrid columns={columns} data={dataSet} />
                </div>

                <h2 className="text-2xl">Ad accounts</h2>
                <BarTilesAndGraph title='Impressions' data={{ tiles: tilesStats, lineChartDataSet }} />
                <BarTilesAndGraph title='Performance (CPM)' data={{ tiles: tilesStats, lineChartDataSet }} />
                <BarTilesAndGraph title='Performance (CPC)' data={{ tiles: tilesStats, lineChartDataSet }} />
                <BarTilesAndGraph title='Spend' data={{ tiles: tilesStats, lineChartDataSet }} />
                <div className="p-4 card-bg-dev rounded-2xl">
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-lg font-bold">List of compaigns</span>
                        <button>
                            <img src={ICON_ENUM?.THREE_DOTS?.icon ?? ''} alt="more options" />
                        </button>
                    </div>
                    <DataGrid columns={compaignColumns} data={dataSet} />
                </div>
            </div>

        </div>
    );
};

export default SocialAnalytics;

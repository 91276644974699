import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import makeApiCall from '../../lib/apiCall';
import { Endpoint } from '../../type/api';
import { SocialAnalyticsState } from '../../type/socialAnalytics';

const initialState: SocialAnalyticsState = {
  loading: false,
  error: null,
  socialLogin: '',
  socialAnalytics: {},
  main: [],
  facebook: [],
  instagram: [],
  youtube: [],
  x: [],
  tiktok: [],
  pinterest: [],
  linkedin: [],
  behance: [],
  snapchat: [],
  thread: [],
};

export const getSocialSigninPage = createAsyncThunk(
  'socialAnalytics/getSocialSigninPage',
  async (content: Endpoint, { rejectWithValue }) => {
    try {
      return await makeApiCall(content);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchsocialAnalyticsData = createAsyncThunk(
  'socialAnalytics/fetchsocialAnalyticsData',
  async (content: Endpoint, { rejectWithValue }) => {
    try {
      return await makeApiCall(content);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const socialAnalyticsSlice = createSlice({
  name: 'socialAnalytics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchsocialAnalyticsData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchsocialAnalyticsData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;

          state.socialAnalytics = action?.payload?.payload ?? {}

        },
      )
      .addCase(fetchsocialAnalyticsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
  // extraReducers: builder => {
  //   builder
  //     .addCase(fetchsocialAnalyticsData.pending, state => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(
  //       fetchsocialAnalyticsData.fulfilled,
  //       (state, action: PayloadAction<any[]>) => {
  //         state.loading = false;
  //         state.error = null;
  //         const products = action.payload;

  //         // Reset all category arrays
  //         Object.keys(state).forEach(key => {
  //           if (Array.isArray(state[key as keyof SocialAnalyticsState])) {
  //             (state[key as keyof SocialAnalyticsState] as any[]) = [];
  //           }
  //         });

  //         products.forEach(product => {
  //           const requiredData: ProductData = {
  //             ...product,
  //             category: product?.category || '',
  //             imageSrc: product?.exploreImage || '',
  //             title: product?.name || '',
  //             subtitle: Array.isArray(product?.additionalInfo?.genre)
  //               ? product?.additionalInfo?.genre?.join(' | ')
  //               : typeof product?.additionalInfo?.genre === 'string'
  //                 ? product?.additionalInfo?.genre
  //                 : product?.subCategory || '',
  //             productId: product?.productId || '',
  //           };

  //           const category = requiredData.category.toUpperCase().trim();

  //           // Use a mapping object with arrays to handle multiple variations
  //           const categoryMap: { [key: string]: (keyof SocialAnalyticsState)[] } =
  //           {
  //             MAIN: [],
  //             FACEBOOK: [],
  //             INSTAGRAM: [],
  //             X: [],
  //             YOUTUBE: [],
  //             LINKEDIN: [],
  //             TIKTOK: [],
  //             BEHANCE: [],
  //             SNAPCHAT: [],
  //             PINTEREST: [],
  //             THRAD: [],
  //           };

  //           const stateKeys = categoryMap[category] || [];
  //           if (stateKeys.length > 0) {
  //             stateKeys.forEach(stateKey => {
  //               (state[stateKey] as ProductData[]).push(requiredData);
  //             });
  //           } else {
  //             console.warn(
  //               `Unknown category: ${category} for product: ${requiredData.productId}`,
  //             );
  //           }
  //         });
  //       },
  //     )
  //     .addCase(fetchsocialAnalyticsData.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload as string;
  //     });
  // },
});
export default socialAnalyticsSlice.reducer;

import axios from "axios";
import { useEffect, useState } from "react";
import Input from "../../components/ui/SettingInput";
import { getCookies } from "../../utils/utils";

const UserFields = ({ isEdit, setIsEdit }: any) => {
    const [userProfile, setUserProfile] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getUserProfile();
        } else {
            console.warn("No internet connection, API calls skipped.");
        }
    }, []);

    const getUserProfile = async () => {
        try {
            let token = getCookies('authToken');
            let userDataResponse = await axios.get('https://api.lusso.dev/api/v1/userProfile', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (userDataResponse) {
                setUserProfile(userDataResponse.data);
            }
            console.log("User data profile is here", userDataResponse);
        } catch (err) {
            console.error(err); // Log any errors
        }
    };

    const updatedUserProfile = async () => {
        try {
            setLoading(true);
            let token = getCookies('authToken');
            console.log("Token here is", token);
            let userDataResponse = await axios.put('https://api.lusso.dev/api/v1/updateProfile', {
                userName: userProfile.username,
                state: userProfile.state,
                country: userProfile.country,
                email: userProfile.email,
                ageGroup: userProfile.ageGroup
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (userDataResponse) {
                setLoading(false);
                console.log({ userDataResponse });
                // Optionally, refresh the user profile data after updating
                getUserProfile();
            }
            console.log("User data profile is here", userDataResponse);
        } catch (err) {
            setLoading(false);
            console.error(err); // Log any errors
        }
    };

    console.log("User profile is here", userProfile);

    return (
        <div className="card-bg-dev rounded-2xl md:p-8 opacity-70 text-white grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="lg:col-span-2">
                <Input
                    label="Name"
                    name="name"
                    value={userProfile?.username}
                    placeholder="Enter your name"
                    onChange={(e) => {
                        setUserProfile({ ...userProfile, username: e.target.value });
                    }}
                    readOnly={!isEdit} // Make it read-only when not in edit mode
                />
            </div>
            <div className="lg:col-span-2">
                <Input
                    label="Email"
                    type="email"
                    value={userProfile?.email}
                    name="email"
                    onChange={(e) => {
                        setUserProfile({ ...userProfile, email: e.target.value });
                    }}
                    placeholder="Enter your email"
                    readOnly={!isEdit} // Make it read-only when not in edit mode
                />
            </div>

            <div>
                <Input
                    label="Country"
                    name="country"
                    value={userProfile?.country}
                    onChange={(e) => {
                        setUserProfile({ ...userProfile, country: e.target.value });
                    }}
                    placeholder="Enter your country"
                    readOnly={!isEdit} // Make it read-only when not in edit mode
                />
            </div>
            <div>
                <Input
                    label="State"
                    value={userProfile?.state}
                    name="state"
                    onChange={(e) => {
                        setUserProfile({ ...userProfile, state: e.target.value });
                    }}
                    placeholder="State"
                    readOnly={!isEdit} // Make it read-only when not in edit mode
                />
            </div>

            <div className="lg:col-span-2">
                <Input
                    label="Age group"
                    value={userProfile?.ageGroup}
                    name="ageGroup"
                    onChange={(e) => {
                        setUserProfile({ ...userProfile, ageGroup: e.target.value });
                    }}
                    placeholder="Age"
                    readOnly={!isEdit} // Make it read-only when not in edit mode
                />
            </div>

            {isEdit && (
                <div className="flex gap-12">
                    <button
                        className={`bg-gradient-vertical rounded-3xl px-10 py-2`}
                        onClick={() => {
                            updatedUserProfile();
                        }}
                    >
                        {!loading ? <p>Save Changes</p> : <div className="loader"></div>}
                    </button>

                    <button
                        className="px-8 py-2 rounded-3xl border-2 bg-transparent"
                        style={{
                            border: '2px solid var(--accent1, #7D3CF3)',
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                            setIsEdit(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserFields;

import { useNavigate } from 'react-router-dom';
import TitleBar from '../../components/common/TitleBar';
import ProductCardNew from '../home/ProductCardNew';

const TrendingProducts = (props: any) => {
  const navigate = useNavigate();
  const { data, hideHeading, allRowDataPopulation } = props;
  const isMobile = window?.innerWidth < 768; // Example mobile breakpoint

  // Split data into rows only if it's not a mobile view
  const rows = !allRowDataPopulation
    ? isMobile
      ? [data]
      : [data.slice(0, 4), data.slice(4, 8)]
    : [data];

  const getCategoryPath = (category: string, title: string) => {
    const categoryPath =
      category?.toLowerCase() === "ai"
        ? "movies"
        : category?.toLowerCase() === "movie"
          ? "movies"
          : category?.toLowerCase() === "course"
            ? "courses"
            : category?.toLowerCase() === "game"
              ? "games"
              : category?.toLowerCase() === "service"
                ? "services"
                : category?.toLowerCase() === "app"
                  ? "apps"
                  : title === "RECOMMENDATIONS"
                    ? "apps"
                    : category?.toLowerCase();

    return categoryPath
  };

  const isLoggedIn = () => {
    const token = document.cookie.split('; ').find(cookie => cookie.startsWith('authToken='));
    return token !== 'authToken=';
  }

  console.log({ rows })

  return (
    <>
      {!hideHeading && <TitleBar title={'Trending Now'} isPopup={props?.isPopup || false} />}
      <div
        className={`overflow-x-auto ${props?.isLandingPage ? 'min-h-[300px]' : 'md:min-h-[600px]'} overflow-y-hidden flex md:mb-0 mb-[10px] md:gap-[1.4rem] gap-2 ${isMobile ? 'flex-nowrap' : 'flex-wrap'
          }`}
      >
        {/* Flex on mobile, wrap on larger screens */}
        {rows.map((row, rowIndex) =>
          row.map((item: any) => {
            const categoryPath = getCategoryPath(item.category, item.title);
            console.log({ categoryPath })
            return (
              <div
                key={item.id}
                className={`trending-product-item ${isMobile ? 'trending-product-mobile' : 'trending-product-web'
                  }`}
              >
                <ProductCardNew
                  item={item}
                  navigateDetails={(id: number) => isLoggedIn() ? navigate(`/explore/${categoryPath}/details/${id}`) : navigate(`/login`)}
                  width={isMobile ? '150px' : '250px'}
                  height={isMobile ? '200px' : '280px'}
                  homeTrending={true}
                />
              </div>
            );
          }),
        )}
      </div>
    </>
  );
};

export default TrendingProducts;

import { checkNullOrEmpty } from "../../utils/utils"

const NoRecordFound = ({ message = '' }: { message?: string }) => {
    return (
        <div className="text-center">
            {checkNullOrEmpty(message) ? 'No Record Found' : message}
        </div>
    )
}

export default NoRecordFound
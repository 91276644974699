import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICON_ENUM } from '../../constants/icons.constant';
import { decodeToken, getTokenFromCookies } from '../../hooks/common.utils';
import FilterBar from '../../pages/explore/FilterBar';

const ExploreAllModalHeader: React.FC<any> = ({ onClose, children, category, sortBy, setSortBy }: any) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const topRef = useRef<HTMLDivElement | any>(null);

    // Initialize user data
    useEffect(() => {
        const token = getTokenFromCookies();
        if (token) {
            const decoded = decodeToken(token);
            setUserData(decoded);
        } else {
            navigate('/auth');
        }
    }, [navigate]);

    const location = useLocation();
    let refreshTab = '';
    if (location.pathname.includes("/addproduct")) {
        refreshTab = 'addproducts';
    }
    if (location.pathname.includes("/manageprofile")) {
        refreshTab = 'manageprofile';
    }

    return (
        <div className="flex flex-col overflow-hidden">
            <main ref={topRef} className="flex-1 overflow-x-hidden overflow-y-auto">
                <div className='flex justify-end items-center md:h-[5rem]'>
                    {/* <div className='flex flex-1'>
                        <ExploreSearch />
                    </div> */}
                    {/* <div className='flex justify-center gap-5 items-center'> */}
                    <button
                        className="btn btn-sm btn-circle btn-ghost"
                        onClick={onClose}
                    >
                        <img src={ICON_ENUM.CROSS.icon} className="h-5" alt="filters" />
                    </button>
                    {/* </div> */}
                </div>
                <div className='flex justify-center relative text-3xl md:text-5xl font-semibold uppercase'>
                    <span>{category || 'Apps'}</span>
                </div>
                {/* Filter */}
                <FilterBar sortBy={sortBy} setSortBy={setSortBy} />

                <div className="mx-auto md:h-[55vh] overflow-y-auto scrollbar-none pt-10">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default ExploreAllModalHeader;

import CircularProgressBar from '../../components/common/CircularProgressBar';
import WorldMap from '../../components/common/WorldMap';

interface LocationType {
    name: string;
    lat: number;
    lng: number;
    value: string;
    percentage?: string;
    flag?: any;
}
interface StatisticsData {
    totalUsers: number;
    activeUsers: number;
    newUsers: number;
    activeUsersPercentage: number;
    newUsersPercentage: number;
    locations?: LocationType[];
}
interface MapStatsProps {
    title?: string;
    data?: StatisticsData;
}

function MapStats({
    title = '',
    data
}: any) {
    console.log("data map", data)
    const GeneralStats = () => {
        return (
            <div className="text-white">
                <h2 className="text-xl font-bold mb-5">{title ?? ''}</h2>
                <p className="text-md font-semibold mb-1">Total users</p>
                <p className="text-4xl font-bold mb-4">
                    {data?.totalUsers?.toLocaleString()}
                </p>

                {data && data?.length !== 0 && (
                    <table className="table mb-4 my-5">
                        <tbody>
                            {data?.map((location: any, index: number) => (
                                <tr key={location?.name + '_' + index}>
                                    {/* <td>
                                        {location?.flag && (
                                            <img src={location?.flag ?? ''} width={30} alt="flag" />
                                        )}
                                    </td> */}
                                    <td className="text-start">
                                        <p>{location?.country ?? ''}</p>
                                    </td>
                                    <td>
                                        <p>{location?.followerCount ?? ''}</p>
                                    </td>
                                    <td>
                                        <p>{location?.percentage ?? ''}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {data?.length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pt-5">
                        {data?.map((location: any, index: number) => (
                            <div key={location?.name + '_' + index} className="flex flex-col items-center  p-4 rounded-lg shadow-md">
                                <div className="flex flex-col items-center gap-5">
                                    <div className="w-[60px]">
                                        <CircularProgressBar percentage={location?.percentage ?? 0} />
                                    </div>
                                    <div className="text-center">
                                        <span className="text-lg font-semibold">{location?.country}</span>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    // render
    return (
        <div className="card-bg-dev rounded-2xl flex flex-col lg:flex-row gap-5 p-6">
            <div className="lg:w-1/4">
                {/* General stats */}
                <GeneralStats />
            </div>

            <div className="lg:w-3/4 flex itemx-center">
                {/* Maps */}
                <WorldMap locations={data ?? []} />
            </div>
        </div>
    );
}

export default MapStats;

import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useState } from "react"; // Import useState
import { useNavigate } from "react-router-dom";
import { getCookies } from "../../utils/utils";
import SettingDrawer from "./components/settingDrawer/settingDrawer";

const DeleteAccountSection = () => {
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [selectedReason, setSelectedReason] = useState(""); // State to capture reason

    const handleCancel = () => {
        close();
    };

    const handleDelete = async () => {
        try {
            let token = getCookies('authToken');
            let userDataResponse = await axios.delete('https://api.lusso.dev/api/v1/userProfile', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    reason: selectedReason // Pass the selected reason dynamically
                }
            });
            if (userDataResponse) {
                navigate("/login");
            }
            console.log("User data profile deleted", userDataResponse);
        } catch (err) {
            console.error("Error deleting account", err);
        }
    };

    const handleReasonChange = (event: any) => {
        setSelectedReason(event.target.value); // Update the selected reason
    };

    const fields = [
        {
            label: "Reason for Leaving",
            name: "reason",
            type: "select",
            options: [
                { label: "Privacy concerns", value: "privacy" },
                { label: "Too many notifications", value: "notifications" },
                { label: "Switching to another platform", value: "switch" },
                { label: "Other", value: "other" }
            ],
            className: "",
            placeholder: "Select Reason",
            onChange: handleReasonChange // Capture reason change
        },
    ];

    const buttons = [
        {
            label: "Delete Account",
            className: "bg-gradient-vertical px-4 py-2 text-white border border-0 rounded",
            onClick: handleDelete
        },
        {
            label: "Cancel",
            className: "px-8 py-2 rounded border-2 bg-transparent",
            onClick: handleCancel,
            style: {
                border: '2px solid var(--accent1, #7D3CF3)',
                backgroundColor: 'transparent',
            }
        },
    ];

    return (
        <>
            <SettingDrawer
                opened={opened}
                close={close}
                title="Delete Account"
                fields={fields}
                buttons={buttons}
            />
            <div className="card-bg-dev rounded-2xl md:p-8 opacity-70 text-white" onClick={() => open()}>
                <label>
                    <span className="text-base font-normal text-[#FFFFFF99]">Delete Account</span>
                </label>

                <label className="input input-bordered flex justify-between items-center gap-2 mt-6"
                    style={{
                        background:
                            "linear-gradient(125.12deg, #2D246C 6.52%, #1A1442 34.28%, #171232 53.59%, #25204A 78.95%)",
                        border: "1px solid var(--outline, #6C8CFF80)"
                    }}>
                    <input type="text" className="grow" placeholder="Delete Account Permanently" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70"
                    >
                        <path
                            fillRule="evenodd"
                            d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708-.707L10.293 8l-3.647-3.646a.5.5 0 1 1 .708-.707l4 4z"
                            clipRule="evenodd"
                        />
                    </svg>
                </label>
            </div>
        </>
    );
};

export default DeleteAccountSection;

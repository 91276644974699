import image1 from '../../assets/images/home/success-section/1.png';
import image2 from '../../assets/images/home/success-section/2.png';
import image3 from '../../assets/images/home/success-section/3.png';
import image4 from '../../assets/images/home/success-section/4.png';
import arrow from '../../assets/images/home/success-section/arrow.png';
interface SuccessCardType {
    index: number
    image: any
    hasVideo: boolean
    link: string,
    text: any,
    path: any,
    subtitle: any
}
const SuccessCard = ({ index, image, hasVideo, link, text, path, subtitle }: SuccessCardType) => {
    return (
        <div
            className={`relative group overflow-hidden 
                        ${index === 0 && 'md:w-7/12 md:pe-5'} 
                        ${index === 1 && 'md:w-5/12'} 
                        ${index === 2 && 'md:w-5/12 md:pe-5'} 
                        ${index === 3 && 'md:w-7/12'} 
                        md:pt-10 pt-2 border-[5px] border-transparent rounded-[5px`}
        >
            <a href={link} target="_blank">
                <img src={image} alt={'image'}
                    className={`md:rounded-none rounded-[10px] w-full md:h-full md:object-cover object-contain
                            ${index === 0 && 'md:rounded-tl-2xl'} 
                            ${index === 1 && 'md:rounded-tr-2xl'}
                            ${index === 2 && 'md:rounded-br-2xl'}
                            ${index === 3 && 'md:rounded-bl-2xl'}
                            h-42`} />
                <div className={`absolute inset-0 flex items-start ${index === 0 ? "top-[20%] left-[10%]" : index === 1 ? "top-[45%] left-[10%]" : index === 2 ? "top-[45%] left-[10%]" : "top-[20%] left-[10%]"} cursor-pointer flex-col gap-4`} style={{
                    lineHeight: 1,
                    margin: 0,
                    padding: 0
                }}>
                    <p className='text-[38px] font-bold w-96 text font-bigShoulders'>{text}</p>
                    <p>{subtitle}</p>
                    <div className='flex items-end gap-2 '>
                        <p>{path}</p>
                        <img className='h-3' src={arrow} alt="" />
                    </div>
                </div>


            </a>
        </div>
    );
};

const EventSection = () => {

    const successStories = [
        { image: image1, hasVideo: true, link: "https://coincheckup.com/blog/lusso-labs-a-catalyst-for-your-business-success/", text: "Business Program now Launched", path: "moreinfo" },
        { image: image2, hasVideo: false, link: "https://www.thecryptoupdates.com/lusso-labs-helping-your-business-reach-its-potential/", text: "AI news this week", path: "moreinfo" },
        { image: image3, hasVideo: false, link: "https://techbullion.com/lusso-labs-a-reliable-partner-for-your-business-success/", text: "AI news this week", path: "moreinfo" },
        { image: image4, hasVideo: true, link: "https://cryptomode.com/news/finance/lusso-labs-empowering-your-business-for-growth/", text: "Business Program now Launched", path: "moreinfo" },
    ];

    return (
        <div className="md:py-10 py-5 md:px-4 px-0 sm:px-6 lg:px-8 max-w-[1370px]">
            <h2 className="text-[18px] md:text-5xl font-bold text-center text-white">
                News And Events
            </h2>
            <div className="flex flex-wrap md:mt-[50px]">
                {successStories.map((story, index = 0) => (
                    <SuccessCard link={story.link} key={index} index={index} image={story.image} hasVideo={story.hasVideo} text={story.text} path={story.path} subtitle={'17 August 2024'} />
                ))}

            </div>
        </div>
    );
};

export default EventSection;

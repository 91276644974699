import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import thread from '../../../../assets/images/social/social-thread.svg';

import image from '../../../../assets/images/Threadimg.png';

import { ICON_ENUM } from '../../../../constants/icons.constant';
import '../SocialAnalytics.scss';

interface GradientStroke {
    [key: string]: { offset: string; stopColor: string }[];
}

const lineChartDataSet = {
    configuration: {
        gradientStroke: {
            likes: [
                { offset: '8.66%', stopColor: '#1C36B7' },
                { offset: '90.78%', stopColor: '#1C98D3' },
            ],
            followers: [
                { offset: '14.6%', stopColor: '#FF0000' },
                { offset: '85.41%', stopColor: '#9B0000' },
            ],
            impressions: [
                { offset: '13.8%', stopColor: '#5342D6' },
                { offset: '18.4%', stopColor: '#7739C6' },
                { offset: '25.1%', stopColor: '#A52DB0' },
                { offset: '28.46%', stopColor: '#B729A8' },
                { offset: '37.01%', stopColor: '#CE257E' },
                { offset: '47.52%', stopColor: '#E82250' },
                { offset: '52.8%', stopColor: '#F2203E' },
                { offset: '65.79%', stopColor: '#F2203E' },
                { offset: '67.35%', stopColor: '#F32D40' },
                { offset: '75.25%', stopColor: '#F86C48' },
                { offset: '81.93%', stopColor: '#FB994E' },
                { offset: '87.06%', stopColor: '#FDB652' },
                { offset: '90.03%', stopColor: '#FEC053' },
            ],
        },
        lines: [
            {
                dataKey: 'likes',
                stroke: 'url(#likes)',
            },
            {
                dataKey: 'followers',
                stroke: 'url(#followers)',
            },
            {
                dataKey: 'impressions',
                stroke: 'url(#impressions)',
            },
            {
                dataKey: 'pageVisits',
            },
        ],
    },
    dataSet: [
        {
            date: 'Aug 8',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 18',
            likes: 1852,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Aug 28',
            likes: 198,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 8',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 18',
            likes: 100,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
        {
            date: 'Sep 28',
            likes: 205,
            followers: 198,
            impressions: 214,
            pageVisits: 167,
        },
    ],
};

const threadStats = {
    topTiles: [
        {
            title: 'Likes',
            borderColor: ICON_ENUM?.FACEBOOK?.borderColor,
            count: '1852',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Followers',
            borderColor: ICON_ENUM?.YOUTUBE?.borderColor,
            count: '198',
            bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Impressions',
            borderColor: ICON_ENUM?.INSTAGRAM?.borderColor,
            count: '214',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Page Visits',
            borderColor: ICON_ENUM?.X?.borderColor,
            count: '167',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
    ],
    bottomTiles: [
        {
            title: 'Likes',
            borderColor: '#6C8CFF80',
            borderWidth: '1px',
            // borderColor: ICON_ENUM?.LINKEDIN?.borderColor,
            count: '1852',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Daily Likes',
            // borderColor: ICON_ENUM?.TIKTOK?.borderColor,
            borderColor: '#6C8CFF80',
            borderWidth: '1px',
            count: '142',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Likes per posts',
            // borderColor: ICON_ENUM?.TWITCH?.borderColor,
            borderColor: '#6C8CFF80',
            borderWidth: '1px',
            count: '715',
            bodyIcon: ICON_ENUM?.UP_TREND?.icon,
            text: '+8.2k increase/day',
        },
        {
            title: 'Daily page views',
            // borderColor: ICON_ENUM?.BEHANCE?.borderColor,
            borderColor: '#6C8CFF80',
            borderWidth: '1px',
            count: '98',
            bodyIcon: ICON_ENUM?.DOWN_TREND?.icon,
            text: '+8.2k increase/day',
        },
    ],
    lineChartDataSet,
};

const Thread = () => {
    return (
        <div className="space-y-8">
            <div className="flex items-center space-x-5">
                <img src={thread} alt="Thread Icon" className="w-5 h-10" />
                <span className="text-[#FFFFFFCC] text-lg font-bold">Threads</span>
            </div>

            <div
                className="card-bg-dev rounded-xl p-4 w-full flex flex-col md:flex-row py-2"
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundOrigin: 'border-box',
                }}
            >
                {/* Content on the left */}
                <div className="md:w-1/2 space-y-8 text-white">
                    <div className="text-2xl font-bold">Threads</div>
                    <p>
                        Measure the reach of your network and post content to your Pinterest
                        account, the newest app from creators of Instagram.
                    </p>
                    <button
                        className="text-[#FFFFFF99] rounded-md p-2 flex items-center justify-between"
                        style={{ background: ICON_ENUM?.TIKTOK?.borderColor }}
                    >
                        Create a Thread Account
                        <img src={thread} alt="Thread Icon" className="w-5 h-5 ms-7" />
                    </button>
                </div>

                {/* Graph card */}
                <div
                    className="md:w-1/2 mt-6 md:mt-0 flex justify-center md:py-12"
                // style={{ marginRight: '20px' }}
                >
                    <div className="w-full md:w-4/5">
                        {/* Adjust width here */}
                        <div className="stats-section-bg p-2 card-bg-dev rounded-lg space-y-3">
                            <div className={`grid grid-cols-2 sm:grid-cols-4 py-2`}>
                                {threadStats?.topTiles?.map((tile: any) => (
                                    <div
                                        className={`social-card rounded-md ${tile?.borderWidth
                                            ? 'p-[' + tile?.borderWidth + ']'
                                            : 'p-[1px]'
                                            }`}
                                        style={{
                                            backgroundColor: tile?.borderColor,
                                            backgroundImage: tile?.borderColor,
                                            backgroundOrigin: 'border-box',
                                            backgroundPosition: 'left,right',
                                            backgroundSize: '100% 100%, 100% 100%',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <div className="w-full h-full px-2 py-1 social-tile-bg rounded-md text-white">
                                            <div className="flex items-center">
                                                <span className="text-[8px]">
                                                    {tile?.title ?? ''}
                                                </span>
                                            </div>
                                            <div>
                                                <div className="flex space-x-7">
                                                    <div className="text-sm font-bold">
                                                        {tile?.count ?? '0'}
                                                    </div>
                                                    <img src={tile?.bodyIcon ?? ''} width={15} />
                                                </div>
                                                <span className="text-[6px]">{tile?.text ?? ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {threadStats?.lineChartDataSet && (
                                <div className="followers-graph mt-6">
                                    <ResponsiveContainer width="100%" height={150}>
                                        <LineChart
                                            data={threadStats?.lineChartDataSet?.dataSet ?? []}
                                        >
                                            {threadStats?.lineChartDataSet?.configuration
                                                ?.gradientStroke &&
                                                Object.keys(
                                                    threadStats?.lineChartDataSet?.configuration
                                                        ?.gradientStroke,
                                                )?.length !== 0 && (
                                                    <defs>
                                                        {Object.keys(
                                                            threadStats?.lineChartDataSet?.configuration
                                                                ?.gradientStroke,
                                                        )?.map((key: string, id: number) => (
                                                            <linearGradient
                                                                key={id + '_' + key}
                                                                id={key}
                                                                x1="0"
                                                                y1="0"
                                                                x2="1"
                                                                y2="1"
                                                            >
                                                                {threadStats?.lineChartDataSet?.configuration?.gradientStroke[
                                                                    key as keyof typeof threadStats.lineChartDataSet.configuration.gradientStroke
                                                                ]?.map((config: any, idx: number) => (
                                                                    <stop
                                                                        key={idx}
                                                                        offset={config?.offset ?? '0%'}
                                                                        stopColor={config?.stopColor ?? '#ffff'}
                                                                    />
                                                                ))}
                                                            </linearGradient>
                                                        ))}
                                                    </defs>
                                                )}
                                            <CartesianGrid
                                                horizontal={true}
                                                vertical={false}
                                                stroke="#ccc"
                                                strokeDasharray="none"
                                            />
                                            <XAxis dataKey="date" />

                                            <YAxis
                                                domain={[0, 5000]}
                                                ticks={[1000, 2000, 3000, 4000, 5000]}
                                                axisLine={false}
                                                tickLine={false}
                                            />
                                            {threadStats?.lineChartDataSet?.configuration?.lines &&
                                                threadStats?.lineChartDataSet?.configuration?.lines?.map(
                                                    (line: any, index: number) => (
                                                        <Line
                                                            key={index + '_' + line?.dataKey}
                                                            type="monotone"
                                                            dataKey={line?.dataKey ?? ''}
                                                            stroke={line?.stroke ?? '#ffff'}
                                                            strokeWidth={4}
                                                            dot={false}
                                                        />
                                                    ),
                                                )}
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            <div className={`grid grid-cols-2 sm:grid-cols-4 py-2`}>
                                {threadStats?.bottomTiles?.map((tile: any) => (
                                    <div
                                        className={`social-card rounded-md ${tile?.borderWidth
                                            ? 'p-[' + tile?.borderWidth + ']'
                                            : 'p-[1px]'
                                            }`}
                                        style={{
                                            backgroundColor: tile?.borderColor,
                                            backgroundImage: tile?.borderColor,
                                            backgroundOrigin: 'border-box',
                                            backgroundPosition: 'left,right',
                                            backgroundSize: '100% 100%, 100% 100%',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <div className="w-full h-full px-2 py-1 social-tile-bg rounded-md text-white">
                                            <div className="flex items-center">
                                                <span className="text-[8px]">
                                                    {tile?.title ?? ''}
                                                </span>
                                            </div>
                                            <div>
                                                <div className="flex space-x-7">
                                                    <div className="text-sm font-bold">
                                                        {tile?.count ?? '0'}
                                                    </div>
                                                    <img src={tile?.bodyIcon ?? ''} width={15} />
                                                </div>
                                                <span className="text-[6px]">{tile?.text ?? ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Thread;

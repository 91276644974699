import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// Load your Stripe public key
const stripePromise = loadStripe(
  'pk_test_51MMGLKK0xOGemk5HLzK5cEVC0DpSAYtxR9NDkD4mviS3jJFAFji3xKU8DwL5a6g9iEbOUQsoH7ywwAsXEBSHzfPy00PbT2jwDp',
); // Replace with your actual Stripe public key
const StripeProvider = ({children}) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
export default StripeProvider;

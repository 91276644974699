import '../navbar/topnavbar.scss';
import FooterLatest from './FooterLatest';
const FooterFeature = () => {
    return (
        <div className="float-left w-full">
            {/* <NewFooter /> */}
            <FooterLatest />
        </div>
    )
}

export default FooterFeature
import { useDisclosure } from '@mantine/hooks';
import Cookies from 'js-cookie';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import profileUser from "../../assets/images/profile.png";
import AlertPopup from '../../components/common/AlertPopup';
import { apiEndpoints } from '../../constants/api-endpoints';
import makeApiCall from '../../lib/apiCall';
import { setCookies } from '../../utils/utils';
import { creatorValidatInput, creatorValidator } from '../developer/validation';
import SettingDrawer from '../userProfile/components/settingDrawer/settingDrawer';
import { PasswordFormData } from '../userProfile/types/types';
import SwitchComponent from './components/SwitchComponent';
import { AccountItems, billItems, ContentItems, feedbackItems, generalItems } from './components/switchItems';
import './settings.scss';

const initialState: PasswordFormData = {
    oldPassword: "",
    newPassword: '',
    confirmPassword: '',
}

const Settings = () => {
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [passOpened, { open: openPass, close: closePass }] = useDisclosure(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [devAuthentication, setDevAuthentication] = useState({
        popup: false,
        message: '',
        redirect: '',
        title: ""
    });
    const [profile, setProfileData] = useState<{ [key: string]: any }>({});
    const [isLoading, setIsLoading] = useState(false);
    const [deletProcess, setDeletProcess] = useState(false);
    const [check, setCheck]: any = useState(false);
    const [checked, setChecked] = useState(true);
    const [formData, setFormData] = useState<PasswordFormData>(initialState);
    const [validation, setValidation] = useState({
        oldPassword: {
            error: false,
            errorMessage: '',
            required: true,
            field: 'current password',
        },
        newPassword: {
            error: false,
            errorMessage: '',
            required: true,
            type: 'password',
            field: 'new password',
        },
        confirmPassword: {
            error: false,
            errorMessage: '',
            required: true,
            field: 'confirm password',
        },
    });

    const onChangeHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        setFormData((prevData) => ({
            ...prevData,
            [name]: trimmedValue,
        }));

        const validationConfig = validation[name as keyof typeof validation];
        const newValidation = await creatorValidatInput(trimmedValue, validationConfig);

        setValidation((prevValidation) => ({
            ...prevValidation,
            [name]: {
                ...prevValidation[name as keyof typeof validation],
                ...newValidation,
            },
        }));
    };


    const onUpdatePassword = async () => {
        const valid = await creatorValidator(formData, validation);
        setValidation((prev) => ({ ...prev, ...valid.validation }));
        try {
            if (valid.isValid) {
                if (formData.newPassword === formData.confirmPassword) {
                    setIsLoading(true);
                    let content = { ...apiEndpoints.updatePassword };
                    content.payload.oldPassword = formData?.oldPassword;
                    content.payload.newPassword = formData.newPassword;
                    const resp = await makeApiCall(content);
                    await closePass()
                    setDevAuthentication({
                        popup: true,
                        message: 'Password updated successfully',
                        redirect: '/dev/settings',
                        title: "Password Update",
                    });
                    setIsLoading(false);
                    setFormData(initialState);
                } else {
                    setValidation((prev) => ({
                        ...prev,
                        confirmPassword: {
                            ...prev.confirmPassword,
                            error: true,
                            errorMessage: "Confirm password must match new password",
                        },
                    }));
                }
            }
        } catch (error: any) {
            closePass()
            setDevAuthentication({
                popup: true,
                message: error?.message,
                title: "Password Update",
                redirect: '/dev/settings',
            });
        }

    };


    const onDeleteAccount = async () => {
        setDeletProcess(true)
        await makeApiCall(apiEndpoints?.deleteUser);
        await close()
        setDeletProcess(false)
        setDevAuthentication({
            popup: true,
            message: 'Account deleted',
            redirect: '/login',
            title: "Delete Account"
        });
    }

    const handleLogout = () => {
        Cookies.remove('authToken');
        Cookies.remove('authUser');
        navigate('/');
    }

    const handleCancel = (flag: string) => {
        if (flag === "delete") {
            close();
        } else {
            closePass()

        }
    };

    const handleReasonChange = (event: any) => {
        setSelectedReason(event.target.value);
    };
    useLayoutEffect(() => {
        fetchCreatorProfile()
    }, []);

    const fetchCreatorProfile = async () => {
        const resp = await makeApiCall(apiEndpoints.userProfile);
        const data: any = {
            fullName: resp?.fullName,
            creatorName: resp?.channelName ?? '',
            email: resp?.email ?? '',
            phoneNumber: resp?.phoneNumber ?? '',
        }
        setFormData(data)
        setProfileData(resp ?? {})
        if (resp) {
            setCookies('authUser', resp)
        }
    }
    const fields = [
        {
            label: "Reason for Leaving",
            name: "reason",
            type: "select",
            options: [
                { label: "Privacy concerns", value: "privacy" },
                { label: "Too many notifications", value: "notifications" },
                { label: "Switching to another platform", value: "switch" },
                { label: "Other", value: "other" }
            ],
            className: "",
            placeholder: "Select Reason",
            onChange: handleReasonChange
        },
    ];

    const buttons = [
        {
            label: "Delete Account",
            className: "bg-gradient-vertical px-4 py-2 text-white border border-0 rounded",
            onClick: onDeleteAccount
        },
        {
            label: "Cancel",
            className: "px-8 py-2 rounded border-2 bg-transparent",
            onClick: () => handleCancel("delete"),
            style: {
                border: '2px solid var(--accent1, #7D3CF3)',
                backgroundColor: 'transparent',
            }
        },
    ];

    const passFields = [
        {
            label: "Current Password",
            name: "oldPassword",
            type: "password",
            // onClick: handlenewPasswordChangeHandler,
            value: formData?.oldPassword,

            placeholder: "Password",
            className: "border border-gray-600 rounded p-2",
            onClick: onChangeHandle,
            required: true,
            validationType: 'password',
            validation: validation

        },
        {
            label: "New Password",
            name: "newPassword",
            type: "password",
            // onClick: handlenewPasswordChangeHandler,
            value: formData?.newPassword,

            placeholder: "Password",
            className: "border border-gray-600 rounded p-2",
            onClick: onChangeHandle,
            required: true,
            validationType: 'password',
            validation: validation

        },
        {
            label: "Confirm Password",
            // onClick: handleConfirmPasswordHandler,
            value: formData?.confirmPassword,

            name: "confirmPassword",
            type: "password",
            placeholder: "Password",
            className: " border border-gray-600 rounded p-2",
            onClick: onChangeHandle,
            required: true,
            validationType: 'match',
            validation: validation
        },
    ];
    const passButtons = [
        {
            label: "Save Changes",
            className: "bg-gradient-vertical px-4 py-2 text-white border border-0 rounded",
            onClick: onUpdatePassword,
        },
        {
            label: "Cancel",
            className: "px-8 py-2 rounded border-2 bg-transparent",
            onClick: () => handleCancel("password"),
            style: {
                border: '2px solid var(--accent1, #7D3CF3)',
                backgroundColor: 'transparent',
            }
        },
    ];
    return (
        <div className='creator-details-container p-4'>
            <AlertPopup
                open={devAuthentication?.popup}
                message={devAuthentication?.message}
                title={devAuthentication?.title}
                onClose={() => {
                    setDevAuthentication({ popup: false, message: '', redirect: '', title: "" })
                    navigate(devAuthentication?.redirect)
                }}
            />
            <SettingDrawer
                opened={opened}
                close={close}
                title="Delete Account"
                fields={fields}
                buttons={buttons}
            />
            <SettingDrawer
                opened={passOpened}
                close={closePass}
                title="Change Password"
                fields={passFields}
                buttons={passButtons}
            />
            <div className="text-white font-bold text-[24px] mb-8">
                <span className="bg-gradient-to-r from-[#985FFF] to-[#FF99EF] bg-clip-text text-transparent">
                    Settings
                </span>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2'>
                <div className='card-bg-dev flex flex-col rounded-lg lg:pr-10 space-y-10 px-10 py-5'>
                    <h2 className="profile-info text-xl text-[#FFFFFF]">Personal Info</h2>
                    <div className='space-y-3 flex gap-12  align-middle'>
                        <div className=' align-middle' >
                            <img src={profileUser} alt="profile" className='rounded-full border-4 border-sky-500' />
                        </div>
                        <div className='flex flex-col space-y-2 text-white align-middle' style={{ marginTop: "30px" }}>
                            <h1 className='font-normal text-3xl text-[#FFFFFF99]'>{profile?.fullName}</h1>
                            <p className='text-[#FFFFFF99]'>Email: <code>{profile?.email}</code></p>
                            <p className='text-[#FFFFFF99]'>Phone Number: <code>{profile?.phoneNumber}</code></p>
                        </div>
                    </div>
                    <div className='space-y-3'>
                        <div className="cursor-pointer" onClick={() => openPass()}>
                            <label
                                className="input input-bordered flex justify-between items-center gap-2 mt-6 text-white cursor-pointer"
                                style={{
                                    background:
                                        "linear-gradient(125.12deg, #2D246C 6.52%, #1A1442 34.28%, #171232 53.59%, #25204A 78.95%)",
                                    border: "1px solid var(--outline, #6C8CFF80)",
                                }}
                            >
                                <input
                                    type="text"
                                    className="grow bg-transparent text-white placeholder-white cursor-pointer"
                                    placeholder="Update Password"
                                    readOnly
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                    className="h-4 w-4 opacity-70 text-white"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708-.707L10.293 8l-3.647-3.646a.5.5 0 1 1 .708-.707l4 4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </label>
                        </div>
                        <div className="cursor-pointer" onClick={() => open()}>
                            <label
                                className="input input-bordered flex justify-between items-center gap-2 mt-6 text-white cursor-pointer"
                                style={{
                                    background:
                                        "linear-gradient(125.12deg, #2D246C 6.52%, #1A1442 34.28%, #171232 53.59%, #25204A 78.95%)",
                                    border: "1px solid var(--outline, #6C8CFF80)",
                                }}
                            >
                                <input
                                    type="text"
                                    className="grow bg-transparent text-white placeholder-white cursor-pointer"
                                    placeholder="Delete Account"
                                    readOnly
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                    className="h-4 w-4 opacity-70 text-white"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708-.707L10.293 8l-3.647-3.646a.5.5 0 1 1 .708-.707l4 4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='card-bg-dev flex flex-col rounded-lg lg:pr-10 space-y-10 px-10 py-5'>
                    <h2 className="profile-info text-xl text-[#FFFFFF]">Notifications</h2>
                    <div className='space-y-4'>
                        <SwitchComponent title="General Notifications:" switchItems={generalItems} />
                        <SwitchComponent title="Content Recommendations:" switchItems={ContentItems} />
                        <SwitchComponent title="Account Security:" switchItems={AccountItems} />
                        <SwitchComponent title="Billing and Subscriptions:" switchItems={billItems} />
                        <SwitchComponent title="Feedback and Surveys:" switchItems={feedbackItems} />
                    </div>
                </div>
                {/* <div className="flex flex-row justify-between items-start settings-container">
                    <div className="flex flex-1 flex-col gap-4">
                        <div>
                            <span className="header-shadow-small">Notifications</span>
                        </div>
                        <div className="flex flex-col gap-3">
                            <span className="sub-header-shadow">General Notifications</span>
                            <div className="flex flex-col gap-3">
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive notifications for important updates and announcements.
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive notifications for important updates and announcements.
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <span className="sub-header-shadow">Content Recommendations:</span>
                            <div className="flex flex-col gap-3">
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive personalized recommendations based on your viewing or
                                        browsing history.
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Get notified when new content is added to your favorite
                                        categories or genres.
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Get alerts for mentions of your username or handle in public
                                        posts.
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <span className="sub-header-shadow">Account Security:</span>
                            <div className="flex flex-col gap-3">
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive notifications for account activity alerts (e.g.,
                                        suspicious login attempts).
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive alerts for successful login attempts from new devices
                                        or locations.
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <span className="sub-header-shadow">Billing and Subscription:</span>
                            <div className="flex flex-col gap-3">
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Receive notifications for upcoming subscription renewals and
                                        payment reminders.
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Get alerts for changes in subscription pricing or plan
                                        upgrades.
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <span className="sub-header-shadow">Feedback and Surveys:</span>
                            <div className="flex flex-col gap-3">
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Get alerted to participate in user research studies or
                                        usability testing.
                                    </p>
                                </label>
                                <label className="ll-checkbox-outline flex gap-x-2 cursor-pointer">
                                    <input
                                        checked={check}
                                        onChange={e => setCheck(!check)}
                                        type="checkbox"
                                        className="hidden"
                                    />
                                    <span
                                        className="w-4 h-4 border border-[#464070] rounded-sm bg-[#353057]"
                                        style={{ borderRadius: 4 }}
                                    ></span>
                                    <p className="text-[#FFF] font-normal text-[12px]">
                                        Get notified when your feedback leads to changes or
                                        improvements on the platform.
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-col">
                            <div>
                                <span className="header-shadow-small">Change Password</span>
                            </div>
                            <div className="mt-3">
                                <label>
                                    <span className="text-white font-normal text-[12px]">
                                        Current Password
                                    </span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="oldPassword"
                                        placeholder="Password"
                                        className="settings-frm-input badge h-[50px]"
                                        value={formData.oldPassword || ''}
                                        style={{
                                            borderRadius: 8,
                                            border: `1px solid #A768FD`,
                                            background: 'rgba(4, 4, 4, 0.20)',
                                            marginTop: 5,
                                        }}
                                        onChange={onChangeHandle}
                                    />
                                    {validation?.oldPassword?.error && (
                                        <span className="errorField mt-3">
                                            {validation?.oldPassword?.errorMessage ?? ''}
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div className="mt-3">
                                <label>
                                    <span className="text-white font-normal text-[12px]">
                                        New Password
                                    </span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="newPassword"
                                        placeholder="New Password"
                                        className="settings-frm-input badge h-[50px]"
                                        value={formData.newPassword || ''}
                                        style={{
                                            borderRadius: 8,
                                            border: `1px solid #A768FD`,
                                            background: 'rgba(4, 4, 4, 0.20)',
                                            marginTop: 5,
                                        }}
                                        onChange={onChangeHandle}
                                    />
                                    {validation?.newPassword?.error && (
                                        <span className="errorField mt-3">
                                            {validation?.newPassword?.errorMessage ?? ''}
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div className="mt-3">
                                <label>
                                    <span className="text-white font-normal text-[12px]">
                                        Confirm Password
                                    </span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        className="settings-frm-input badge h-[50px]"
                                        value={formData.confirmPassword || ''}
                                        style={{
                                            borderRadius: 8,
                                            border: `1px solid #A768FD`,
                                            background: 'rgba(4, 4, 4, 0.20)',
                                            marginTop: 5,
                                        }}
                                        onChange={onChangeHandle}
                                    />
                                    {validation?.confirmPassword?.error && (
                                        <span className="errorField mt-3">
                                            {validation?.confirmPassword?.errorMessage ?? ''}
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div>
                                <button
                                    className="saveContinue mt-3"
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid #A768FD',
                                        background:
                                            'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={onUpdatePassword}
                                    disabled={isLoading}
                                >
                                    <Spinner spin={isLoading}>Update Password</Spinner>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col mt-8">
                            <div>
                                <span className="header-shadow-small">Delete Account</span>
                            </div>
                            <div className="mt-3">
                                <span className="text-white font-normal text-[12px]">
                                    Permanently remove your account and all associated data from the
                                    platform. Please note that this action is irreversible and will
                                    result in the loss of all account-related content and
                                    information.
                                </span>
                            </div>
                            <div>
                                <button
                                    className="saveContinue mt-3"
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid #A768FD',
                                        background:
                                            'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={onDeleteAccount}
                                    disabled={deletProcess}
                                >
                                    <Spinner spin={deletProcess}>Delete Account</Spinner>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div >
        </div >
    );
};

export default Settings;

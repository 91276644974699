import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ProductListCard from '../../../../components/cards/ProductListCard';
import { LineDraw } from '../GameDetailsScreen';

type RecommendedDataItem = {
    title: string;
    subtitle: string;
    image: string;
    rating: number;
};


type TrendingSidebarItem = {
    id: string;
    title: string;
    subtitle: string;
    image: string;
    rating: number;
    category: string;
    productId: string
};

type SidebarProps = {
    sidebarTitle?: string;
    trendingData: TrendingSidebarItem[];
    recommendedData: RecommendedDataItem[];
};

const Sidebar: React.FC<SidebarProps> = ({ sidebarTitle, trendingData, recommendedData }) => {
    const navigate = useNavigate();

    const handleCardClick = (game: TrendingSidebarItem) => {
        const category = game.category

        console.log({ category })

        let categoryPath =
            category?.toLowerCase() === 'ai' ? 'movies' :
                category?.toLowerCase() === 'movie' ? 'movies' :
                    category?.toLowerCase() === 'course' ? 'courses' :
                        category?.toLowerCase() === 'game' ? 'games' :
                            category?.toLowerCase() === 'service' ? 'services' :
                                category?.toLowerCase() === 'app' ? 'apps' : "apps"
        navigate(`/explore/${categoryPath}/details/${game.productId}`);
    };

    return (
        <div className="hidden w-full md:w-1/3 mt-8 md:mt-0 md:flex flex-col gap-10">
            <div className="bg-[#161328] py-10 px-8">
                <h2 className="text-2xl font-bold mb-4 flex gap-2 items-center">
                    {sidebarTitle ? sidebarTitle : 'PEOPLE ALSO VIEW'}
                    <ChevronRight size={25} />
                </h2>
                <LineDraw />
                <div className="mt-5 grid gap-6 md:grid-cols-1">
                    {trendingData.map((game, index) => (
                        <div
                            key={index}
                            onClick={() => handleCardClick(game)}
                            className="cursor-pointer"
                        >
                            <ProductListCard game={game} bg="rgba(38,34,66,255)" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
